import { DocumentSnapshot, Timestamp } from "../firestoreImports"
import { User } from "./user"
import { Place, Direction } from "./other"

export class Route {
    constructor(
        public id: string,
        public title: string,
        public origin: Place,
        public destination: Place,
        public userId: string,
        public schedule: number[],
        public duration: number,
        public companyCode: string,
        public canDrive: boolean,
        public maxDetour: number,
        public carId?: string,
        public destArrival?: number,
        public destDeparture?: number,
        public note?: string,
        public timestampCreated?: Date,
        public canceled?: boolean,
        public noParking?: boolean
    ) {}

    //Helpers
    driver?: User

    static fromData(doc: DocumentSnapshot): Route | null {
        if (!doc.data()) {
            console.warn(`Cannot create Ride ${doc.id} from snapshot`)
            return null
        }
        const data = doc.data()!

        //WARN property names changed!
        const origin = new Place(data.originName, data.originLat, data.originLng, data.originPlaceId)
        const destination = new Place(data.destName, data.destLat, data.destLng, data.destPlaceId)

        const timestampCreated = (data.timestampCreated as Timestamp | undefined)?.toDate()

        if (!data.destArrival && !data.destDeparture) {
            //One or other has to be present
            return null
        }

        let schedule = data.schedule
        if (!Array.isArray(schedule)) {
            schedule = []
        }

        return new Route(
            doc.id,
            data.title,
            origin,
            destination,
            data.userId,
            schedule,
            data.duration,
            data.company,
            data.canDrive ?? true,
            data.maxDetour ?? 600,
            data.carId,
            data.destArrival,
            data.destDeparture,
            data.note,
            timestampCreated,
            data.canceled,
            data.noParking ?? false
        )
    }

    //Creates new key value object to save new ride on firestore
    asData(): { [k: string]: any } {
        const data: { [k: string]: any } = {
            title: this.title,
            originName: this.origin.name,
            originLat: this.origin.lat,
            originLng: this.origin.lng,
            destName: this.destination.name,
            destLat: this.destination.lat,
            destLng: this.destination.lng,
            userId: this.userId,
            schedule: this.schedule,
            duration: this.duration,
            company: this.companyCode,
            timestampCreated: this.timestampCreated ?? Timestamp.now(),
            noParking: this.noParking ?? false,
            maxDetour: this.maxDetour,
            canDrive: this.canDrive
        }
        if (this.destArrival) {
            data.destArrival = this.destArrival
        }
        if (this.destDeparture) {
            data.destDeparture = this.destDeparture
        }
        if (this.origin.placeId) {
            data.originPlaceId = this.origin.placeId
        }
        if (this.destination.placeId) {
            data.destPlaceId = this.destination.placeId
        }
        if (this.canceled) {
            data.canceled = this.canceled
        }
        if (this.carId) {
            data.carId = this.carId
        }
        return data
    }

    direction = () => {
        if (this.destArrival === undefined) {
            return Direction.Backward
        }
        if (this.destDeparture === undefined) {
            return Direction.Forward
        }
        return null
    }
}
