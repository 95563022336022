import React, { BaseSyntheticEvent } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { slide as Menu } from 'react-burger-menu';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import withReactContent from 'sweetalert2-react-content'
import NavDropdown from 'react-bootstrap/NavDropdown';

import * as ROUTES from 'constants/routes';
import Firebase, { withFirebase, Language } from 'components/Firebase';
import { AuthUserContext, UserState, UserAuthInfo} from 'shared-components/Session';

import "styles/navigation.scss";

import logo from "assets/logo-white.svg";
//import { AuthUserInfo } from "firebase-functions/lib/common/providers/identity";
import { userInfo } from "os";
//import user_icon from "assets/icons/user.svg"

const MySwal = withReactContent(Swal)

interface IUserInfoProps {
    firebase: Firebase
    userInfo?: UserAuthInfo
    handleOnClick(): void
    intl: IntlShape
}

class UserInfo extends React.Component<IUserInfoProps> {

    handleSignOutClick = () => {
        MySwal.showLoading()
        return this.props.firebase.signOut()
        .then(_ => {
            return MySwal.fire({
                title: this.props.intl.formatMessage({id: 'general.done'}),
                confirmButtonText: 'OK',
                icon: 'success',
                timer: 1500,
                timerProgressBar: true
            })
        }).then(_ => {
            this.props.handleOnClick()
        })
    }

    render() {
        switch (this.props.userInfo?.state) {
            case UserState.Unknown:
                return (
                    <Link className="menu-item" to="">Loading ...</Link>
                )
            case UserState.Auth:
                /*
                const dropDownTitle = (
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"className="small-img">
                            <path className="cls-1" d="M15,15a6.33,6.33,0,1,1,6.33-6.33A6.34,6.34,0,0,1,15,15Zm10.61,4.39a15,15,0,0,0-5.7-3.57,8.67,8.67,0,1,0-9.82,0A15,15,0,0,0,0,30H2.34a12.66,12.66,0,1,1,25.32,0H30A14.93,14.93,0,0,0,25.61,19.39Z"/>
                        </svg>
                        {this.props.authUser.name}
                    </div>
                )*/
                return (
                    <React.Fragment>
                        <Link className="menu-item" to={ROUTES.PROFILE} onClick={this.props.handleOnClick}>
                            <FormattedMessage id="nav.my_profile"/>
                        </Link>
                        <Link className="menu-item" to={ROUTES.CONNECTIONS} onClick={this.handleSignOutClick}>
                            <FormattedMessage id="user.sign_out"/>
                        </Link>
                    </React.Fragment>
                )
            default:
                return (
                    <React.Fragment>
                        <Link className="menu-item" to={ROUTES.SIGN_UP} onClick={this.props.handleOnClick}>
                            <FormattedMessage id="nav.sign_up"/>
                        </Link>
                        <Link className="menu-item" to={ROUTES.SIGN_IN} onClick={this.props.handleOnClick}>
                            <FormattedMessage id="user.sign_in"/>
                        </Link>
                    </React.Fragment>
                )
            }
    }
}

interface INavigationPanelState {
    isOpen: boolean
    allowedLocationIds?: string[]
    allowedCompanies?: string[]
}
interface INavigationPanelProps {
    setLanguage: (languageId: string) => void
    selectedLanguageId: string
    languages: Language[]
    firebase: Firebase
    intl: IntlShape
    userInfo: UserAuthInfo
}

class NavigationPanel extends React.Component<INavigationPanelProps, INavigationPanelState> {
    constructor(props: INavigationPanelProps) {
        super(props)

        this.state = {
            isOpen: this.isBigScreen(),
            allowedLocationIds: undefined,
            allowedCompanies: undefined
        }
    }

    updateOpenState = () => {
        this.setState({isOpen: this.isBigScreen()})
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateOpenState);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateOpenState);
    }

    componentDidUpdate(prevProps: INavigationPanelProps) {
        if (prevProps.userInfo.state !== this.props.userInfo.state) {
            this.fetchAdminPrivileges()
        }
    }

    isBigScreen = () => {
        return window.innerWidth > 768
    }

    handleOnClick = () => {
        this.handleOnClose()
    }

    handleOnOpen = () => {
        this.setState({isOpen: !this.state.isOpen})
    };

    handleOnClose = () => {
        if (!this.isBigScreen()) {
            this.setState({isOpen: false})
        }
    };

    handleLanguageSelect = (event: BaseSyntheticEvent) => {
        const languageId = event?.target?.id

        if (this.props.languages.find(l => l.id === languageId) !== undefined) {
            this.props.setLanguage(languageId)
        }
    }

    fetchAdminPrivileges = () => {
        if (this.props.userInfo.state === UserState.Auth && this.props.userInfo?.user?.uid) {
            this.props.firebase.fetchAdminPrivileges(this.props.userInfo.user?.uid).then(privileges => {
                this.setState({allowedLocationIds: privileges.locationsIds, allowedCompanies: privileges.companies})
            }).catch(error => {
                this.setState({allowedLocationIds: [], allowedCompanies: []})
                console.warn("Error fetching admin privileges: ", error)
            })
        } else {
            this.setState({allowedLocationIds: [], allowedCompanies: []})
        }
    }


    render() {
    return (
        <Menu noOverlay={this.isBigScreen()}
            disableOverlayClick={this.isBigScreen()}
            disableCloseOnEsc={this.isBigScreen()}
            isOpen={this.state.isOpen}
            customCrossIcon={false}
            width={260}
            disableAutoFocus
            onOpen={this.handleOnOpen}
            onClose={this.handleOnClose}
            burgerBarClassName={this.state.isOpen ? "bm-burger-bars_open" : "bm-burger-bars_closed"}
            outerContainerId="root"
        >
            <div className="menu-section">
                <div className="menu-spacer d-md-none"></div>
                <Link to={ROUTES.CONNECTIONS} className="m-3 logo-block d-none d-md-block" onClick={this.handleOnClick}>
                    <img className="logo" src={logo} alt="logo" />
                </Link>
                {/*<Link className="menu-item" to={ROUTES.FIND_RIDE} onClick={this.handleOnClick}>
                    <FormattedMessage id="nav.find_a_ride" />
                </Link>*/}
                <Link className="menu-item" to={ROUTES.CONNECTIONS} onClick={this.handleOnClick}>
                    <FormattedMessage id="nav.possible_connections" />
                </Link>
                <Link className="menu-item" to={ROUTES.OFFER} onClick={this.handleOnClick}>
                    <FormattedMessage id="nav.create_route" />
                </Link>
                <Link className="menu-item" to={ROUTES.MY_RIDES} onClick={this.handleOnClick}>
                    <FormattedMessage id="nav.my_rides" />
                </Link>
                <Link className="menu-item" to={ROUTES.MY_ROUTES} onClick={this.handleOnClick}>
                    <FormattedMessage id="nav.my_routes" />
                </Link>
                {((this.state.allowedCompanies?.length ?? 0) > 0) ?
                    <React.Fragment>
                        <hr className="my-1" />
                        <Link className="menu-item" to={ROUTES.ADMIN} onClick={this.handleOnClick}>Admin</Link>
                        <Link className="menu-item" to={ROUTES.ANALYTICS} onClick={this.handleOnClick}>Analytics</Link>
                        <Link className="menu-item" to={ROUTES.USER_MANAGEMENT} onClick={this.handleOnClick}>User Management</Link>
                    </React.Fragment>
                    : <div></div> }
                {!this.props.userInfo?.user?.email.includes("@hitchme.cz") ? <div></div> : (
                    <React.Fragment>
                        <hr className="my-1" />
                        <Link className="menu-item" to={ROUTES.HM_ADMIN} onClick={this.handleOnClick}>HM Admin</Link>
                    </React.Fragment>
                )}
            </div>
            <div className="menu-section">
                <NavDropdown id="nav-dropdown" className="menu-item" title={this.props.languages.find(l => l.id === this.props.selectedLanguageId)?.title ?? this.props.languages[0].title}>
                    {this.props.languages.map(language => {
                        return <NavDropdown.Item key={language.id} id={language.id} onClick={this.handleLanguageSelect}>{language.title}</NavDropdown.Item>
                    })}
                </NavDropdown>
                <hr className="my-1" />
                <UserInfo firebase={this.props.firebase} userInfo={this.props.userInfo} handleOnClick={this.handleOnClick} intl={this.props.intl} />
                <hr className="my-1" />
                <Link className="menu-item" to={ROUTES.CONTACTS} onClick={this.handleOnClick}>
                    <FormattedMessage id="footer.contacts" />
                </Link>
                <Link className="menu-item" to={ROUTES.FAQ} onClick={this.handleOnClick}>
                    <FormattedMessage id="footer.faq" />
                </Link>
                <hr className="my-1" />
                <a target="_blank" rel="noopener noreferrer" className="menu-item" href={"https://www.yedem.io/"+this.props.selectedLanguageId+"/terms"} onClick={this.handleOnClick}><FormattedMessage id="footer.terms" /></a>
                <a target="_blank" rel="noopener noreferrer" className="menu-item" href={"https://www.yedem.io/"+this.props.selectedLanguageId+"/pp"} onClick={this.handleOnClick}><FormattedMessage id="footer.pp" /></a>
            </div>
        </Menu>
    )}
}

const NavigationPanelBase = (props: any) => (
    <AuthUserContext.Consumer>
        {userInfo =>
            <NavigationPanel {...props} userInfo={userInfo} />
        }
    </AuthUserContext.Consumer>
)

export default injectIntl(withFirebase(NavigationPanelBase))
