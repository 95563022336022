import axios, { AxiosError, AxiosResponse } from 'axios';
import { ApiError, ValidationResult, DomainValidationResult, ApiErrorCode, Route, NewRide } from '.'
import moment from'moment'
import { Direction } from './models/other';

export default class WebAPI {
    static apiAddress = process.env.REACT_APP_WEB_API + '/webApi/api/v1'

    static validateSignUpCode = (code: string): Promise<ValidationResult> => {
        return axios.post(WebAPI.apiAddress + '/validateSignUpCode', {
            signUpCode: code
        }).then(response => {
            if (response.status === 200 && response.data) {
                const data = response.data
                if (data.valid && data.company && data.locationIds) {
                    return Promise.resolve({valid: true, company: data.company, locationIds: data.locationIds})
                }
                return Promise.resolve({valid: false})
            } else {
                return Promise.reject(`Response status code ${response.status}, ${response.data}`)
            }
        }).catch(error => {
            console.log("Error validating code: ", error)
            return Promise.reject(new Error('Error validating code'))
        })
    }

    static validateSignUpDomain = (domain: string) => {
        return axios.post(WebAPI.apiAddress + '/validateSignUpDomain', {
            signUpDomain: domain
        }).then(response => {
            if (response.status === 200 && response.data) {
                const data = response.data
                return WebAPI.processValidationResponse(data)
            } else {
                return Promise.reject(`Response status code ${response.status}, ${response.data}`)
            }
        }).catch(error => {
            console.log("Error validating domain: ", error)
            return Promise.reject(`Error validating domain`)
        })
    }

    private static processValidationResponse = (data: any): Promise<DomainValidationResult> => {
        if (data.valid && data.company && data.locationIds) {
            return Promise.resolve({
                valid: true, company: data.company, locationIds: data.locationIds,
                ssoProviderId: data.ssoProviderId, passwordEnabled: data.passwordEnabled ?? true
            })
        }
        return Promise.resolve({valid: false})
    }



    static getSlackAccessToken = (code: string) => {
        return axios.post('https://slack.com/api/oauth.v2.access', {
                code: code,
                client_id: '609999784001.3962879066695',
                client_secret: 'b630924c6b68e53e863873b0197e74d0'
            }, {
                headers: {"Content-Type": "multipart/form-data"}
            }).catch(error => {
                console.log("Error geting access token: ", error)
                if (error.response) {
                    console.log(error.response.data);
                }
                return Promise.reject(`Authorization failed`)
            }).then(response => {
                if (response.status === 200 && response.data) {
                    const data = response.data
                    if (data.ok && data.ok === true && data.access_token && data.authed_user && data.authed_user.id) {
                        return Promise.resolve([data.access_token, data.authed_user.id])
                    } else if (data.error) {
                        console.log(data.error)
                        return Promise.reject(new ApiError(`Authorization failed (${data.error})`))
                    } else {
                        return Promise.reject(new ApiError("Authorization failed"))
                    }
                } else {
                    return Promise.reject(`Response status code ${response.status}, ${response.data}`)
                }
            })
    }

    static validateApiResponse = (response: AxiosResponse) => {
        if (response.status !== 200 || !response.data) {
            throw new Error(`Invalid response status code ${response.status}, ${response.data}`)
        }
        const data = response.data
        if (!data.status || data.status !== 'OK' ) {
            if (data.errorCode) {
                 throw new ApiError(`API response error: ${ApiErrorCode[data.errorCode]} (${data.errorCode}) ${data.description}`)
            }
            throw new Error(`API response unknown error ${JSON.stringify(data)}`)
        }
        return data
    }

    static joinRides = async (requests: {matchId: string, destDatetime: Date}[]) => {
        const requestBody = requests.map(request => {return {matchId: request.matchId, destDatetime: Math.floor(request.destDatetime.getTime() / 1000)}} )
        try {
            const response = await axios.post(WebAPI.apiAddress + '/joinRides', {requests: requestBody})
            const data = await this.validateApiResponse(response)
            if (data.connectionIds === undefined || !Array.isArray(data.connectionIds)) {
                throw new Error(`API response unknown error ${JSON.stringify(data)}`)
            }
            return data.connectionIds as string[]
        } catch(error) {
            console.log("Error joining rides: ", error)
            return Promise.reject(`Error joining rides`)
        }
    }

    static cancelRides = async (rides: NewRide[]): Promise<void> => {
        const requestBody = rides.map(ride => {
            return {
                routeId: ride.routeId,
                destDatetime: Math.floor(ride.destDatetime.getTime() / 1000),
                direction: ride.direction
            }
        })
        try {
            const response = await axios.post(WebAPI.apiAddress + '/cancelRides', {requests: requestBody})
            await this.validateApiResponse(response)
        } catch(error) {
            console.log("Error canceling rides: ", error)
            return Promise.reject(`Error canceling rides`)
        }
    }

    static createRoute = async (route: Route, instancesDays: Date[] = []) => {
        const requestBody: {[k: string]: any} = {
            title: route.title,
            schedule: route.schedule,
            origin: route.origin.asData(),
            destination: route.destination.asData(),
            userId: route.userId,
            canDrive: route.canDrive,
            noParking: route.noParking ?? false,
            instancesDays: instancesDays.map(i => moment(i).unix())
        }
        if (route.destArrival) {
            requestBody.destArrival = route.destArrival
        }
        if (route.destDeparture) {
            requestBody.destDeparture = route.destDeparture
        }
        if (route.maxDetour) {
            requestBody.maxDetour = route.maxDetour
        }
        if (route.carId) {
            requestBody.carId = route.carId
        }
        if (route.note) {
            requestBody.note = route.note
        }
        try {
            const response = await axios.post(WebAPI.apiAddress + '/createRoute', requestBody)
            const data = await this.validateApiResponse(response)
            if (data.routeId) {
                return data.routeId as string
            } else {
                throw new Error(`CreateRoute response does not contain routeId ${JSON.stringify(data)}`)
            }
        } catch(error) {
            console.log("Error creating route: ", error)
            if (error instanceof AxiosError) {
                return Promise.reject(error.response?.data?.description ?? error)
            } else {
                return Promise.reject(`Error creating route`)
            }
        }
    }
}