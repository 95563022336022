import React from 'react';

export class FormRow extends React.Component {
    render() {
        if (this.props.hidden) {
            return <></>
        }
        return (
            <div className="row margin vertical-align">
                <label className="col-md-4 col-sm-5" htmlFor={this.props.labelFor}>{this.props.label}</label>
                {this.props.children}
                <span className="col-md-4 col-sm-2"></span>
            </div>
        )
    }
}