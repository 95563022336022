import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { UserStateCheck } from 'shared-components/Session';

import {withFirebase} from 'components/Firebase';
import {FormattedMessage} from 'react-intl';

import {ReactComponent as RoadCar} from 'assets/icons/road_car_blue.svg';

const RequestState = {
    Pending: 0,
    Finished: 1,
    Failed: 2
};

class AcceptBase extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            requestState: RequestState.Pending
        };
    }

    componentDidMount() {
        this.props.firebase.respondToConnection(this.props.router.params.connectionId, true)
        .then(_ => {
            this.setState({requestState: RequestState.Finished})
        }).catch(error => {
            console.warn("Error accepting ride ", error)
            this.setState({requestState: RequestState.Failed})
        })
    }

    render() {
        switch (this.state.requestState) {
            case RequestState.Pending:
                return (
                    <React.Fragment>
                        <h2><FormattedMessage id="accept.accepting"/></h2>
                        <div className = "loader"></div>
                    </React.Fragment>
                )
            case RequestState.Finished:
                return (
                    <React.Fragment>
                        <h2><FormattedMessage id="accept.accepted"/></h2>
                        <div className = "box-center bigger-text">
                            <div className = "content-left">
                                <FormattedMessage id="accept.accepted_msg1"/><br />
                                <FormattedMessage id="accept.accepted_msg2"/><br />
                                <FormattedMessage id="accept.accepted_msg3"/><br />
                                <div className="text-center">
                                    <RoadCar className="w-70 m-3" /><br />
                                </div>
                                <FormattedMessage id="accept.accepted_msg4"/><br /><br />
                            </div>
                        </div>
                    </React.Fragment>
                )
            default: //RequestState.Failed
                return (
                    <React.Fragment>
                        <h2> <FormattedMessage id="accept.request_failed"/></h2>
                        <div className = "box-center bigger-text">
                            <div className = "content-left">
                            <FormattedMessage id="accept.try_again_later"/>
                            </div>
                        </div>
                    </React.Fragment>
                )
        }
    }
}

const Accept = props => (
    <div className="caption-container center-container">
    <br />
    <UserStateCheck allowNoUser={true}>
        {userInfo =>
                <Fade><AcceptBase {...props} /></Fade>
        }
    </UserStateCheck>
    </div>
)

export default withRouter(withFirebase(Accept));

import {
    useLocation,
    useNavigate,
    useParams,
  } from "react-router-dom";

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
        <Component
        {...props}
        router={{ location, navigate, params }}
        />
    );
    }

    return ComponentWithRouterProp;
  }