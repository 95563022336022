// This must be the first line in src/index.js
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import FirebaseContext, {withFirebase} from './context';
import Firebase from './firebase';
import { User, UserPriorityLevel } from './models/user';
import { Ride, RideType, NewRide, RidesPair } from './models/ride';
import { Car, EngineType } from './models/car';
import { Location } from './models/location';
import { Company } from './models/company';
import { Language, Collections } from './models/other';
import { ApiError, ApiErrorCode, ValidationResult, DomainValidationResult } from './models/webApi';
import { Connection } from './models/connection';
import { Place } from './models/other';
import { Route } from './models/route'
import { Match } from './models/match';

export default Firebase;

export {
    FirebaseContext, withFirebase, RideType, ApiError, ApiErrorCode, Ride, User, Car, EngineType, Language,
    Location, UserPriorityLevel, Company, Collections, Connection, Place, Route, NewRide, Match, RidesPair
}
export type { ValidationResult, DomainValidationResult }