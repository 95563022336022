import React from "react"
import { Link } from "react-router-dom"

import { AuthUserContext, UserState, UserAuthInfo } from "../../shared-components/Session"

import * as ROUTES from "../../constants/routes"
import { FormattedMessage } from "react-intl"

interface IUserStateCheckProps {
    allowBroken: boolean
    allowNoUser: boolean
    children: (params: UserAuthInfo) => JSX.Element
}

const UserStateCheck = (props: IUserStateCheckProps) => (
    <AuthUserContext.Consumer>
        {userInfo => {
            switch (userInfo.state) {
                case UserState.NoUser:
                    if (!props.allowNoUser) {
                        return (
                            <div className="center-container">
                                <h5>
                                    <FormattedMessage id="general.sign_in" values={{ link: label => <Link to={ROUTES.SIGN_IN}>{label}</Link> }} />
                                </h5>
                                <h5>
                                    <FormattedMessage
                                        id="general.sign_in_no_account"
                                        values={{ link: label => <Link to={ROUTES.SIGN_UP}>{label}</Link> }}
                                    />
                                </h5>
                            </div>
                        )
                    } else {
                        return props.children(userInfo)
                    }
                case UserState.Unknown:
                    return <div className="loader"></div>
                case UserState.Deleted:
                    return (
                        <div className="center-container">
                            <h5>
                                <FormattedMessage id="general.user_deleted" />
                            </h5>
                        </div>
                    )
                case UserState.Broken:
                    if (!props.allowBroken)
                        return (
                            <div className="center-container">
                                <h5>
                                    <FormattedMessage
                                        id="general.user_broken"
                                        values={{ link: label => <Link to={ROUTES.EDIT_PROFILE}>{label}</Link> }}
                                    />
                                </h5>
                            </div>
                        )
                    else return props.children(userInfo)
                case UserState.Auth:
                    return props.children(userInfo)
            }
        }}
    </AuthUserContext.Consumer>
)
// Set default props
UserStateCheck.defaultProps = {
    allowNoUser: false,
    allowBroken: false
}
export default UserStateCheck
