import React from "react"
import { Link } from "react-router-dom"
import { Fade } from "react-awesome-reveal"

import { UserStateCheck } from "shared-components/Session"
import { withFirebase, EngineType } from "components/Firebase"
import Swal from "sweetalert2/dist/sweetalert2.js"
import withReactContent from "sweetalert2-react-content"
import { injectIntl, FormattedMessage } from "react-intl"

import * as ROUTES from "constants/routes"

import "styles/userProfile.scss"

import car_white from "assets/icons/car-white-left.svg"
import car_white_el from "assets/icons/car-white-left-el.svg"
import car_white_ng from "assets/icons/car-white-left-ng.svg"

const MySwal = withReactContent(Swal)

export class InfoCell extends React.Component {
    render() {
        return (
            <div className="row margin">
                <span className="col-sm-6 text-end align-self-center">{this.props.cellTitle}</span>
                <span className="col-sm-6 text-start">{this.props.cellValue}</span>
            </div>
        )
    }
}

const ProfilePane = ({ user }) => (
    <div className="profile-tab">
        <h2>
            <FormattedMessage id="profile.my_details" />
        </h2>
        <InfoCell cellTitle={<FormattedMessage id="profile.name" />} cellValue={user.name + " " + user.surname} />
        <InfoCell cellTitle={<FormattedMessage id="profile.email" />} cellValue={user.email} />
        <InfoCell cellTitle={<FormattedMessage id="profile.phone" />} cellValue={user.phone} />
        <div className="row margin">
            <span className="col-sm-12">
                <Link to={ROUTES.EDIT_PROFILE}>
                    <button type="button" className="btn hm-btn btn-primary">
                        <FormattedMessage id="profile.edit" />
                    </button>
                </Link>
            </span>
        </div>
    </div>
)

class PointsPane extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            passengerConnections: null,
            driverConnections: null
        }
    }

    componentDidMount() {
        if (!this.props.userAuthInfo.user || !this.props.userAuthInfo.user.uid) {
            return
        }

        this.props.firebase
            .fetchPassengerConnections(this.props.userAuthInfo.user.uid)
            .then(connections => {
                this.setState({ passengerConnections: connections })
            })
            .catch(error => {
                console.warn("Getting connection failed: ", error.message)
            })

        this.props.firebase
            .fetchDriverConnections(this.props.userAuthInfo.user.uid)
            .then(connections => {
                this.setState({ driverConnections: connections })
            })
            .catch(error => {
                console.warn("Getting connection failed: ", error.message)
            })
    }

    countPoints = (thisMonth = false) => {
        let passengerPoints = 0
        if (this.state.passengerConnections) {
            let connections = this.state.passengerConnections.filter(c => c.rideDatetime * 1000 < Date.now() && c.accepted && !c.canceled)
            if (thisMonth) {
                const date = new Date()
                const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
                connections = connections.filter(c => c.rideDatetime * 1000 > firstDay)
            }

            passengerPoints = connections.length * 10
        }

        let driverPoints = 0
        if (this.state.driverConnections) {
            let connections = this.state.driverConnections.filter(c => c.rideDatetime * 1000 < Date.now() && c.accepted && !c.canceled)
            if (thisMonth) {
                const date = new Date()
                const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
                connections = connections.filter(c => c.rideDatetime * 1000 > firstDay)
            }
            const groups = connections.reduce((groups, connection) => {
                if (!groups[connection.rideId]) {
                    groups[connection.rideId] = []
                }
                groups[connection.rideId].push(connection)
                return groups
            }, {})
            const connectionsCount = Object.keys(groups).map(rideId => groups[rideId].length)
            driverPoints = connectionsCount.map(connections => (connections > 1 ? 10 : 10)).reduce((acc, points) => points + acc, 0)
        }

        return passengerPoints + driverPoints
    }

    render() {
        return (
            <React.Fragment>
                <div className="profile-tab">
                    <h2>
                        <FormattedMessage id="profile.my_points" />
                    </h2>
                    <InfoCell cellTitle={<FormattedMessage id="profile.this_month" />} cellValue={this.countPoints(true)} />
                    <InfoCell cellTitle={<FormattedMessage id="profile.total_points" />} cellValue={this.countPoints()} />
                    <div className="small">
                        <FormattedMessage id="profile.points_explenation" />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
const PointsPaneFirebase = withFirebase(PointsPane)

const Car = ({ car }) => {
    let carImage
    switch (car.engineType) {
        case EngineType.Electric:
            carImage = car_white_el
            break
        case EngineType.NaturalGas:
            carImage = car_white_ng
            break
        default:
            carImage = car_white
    }
    return (
        <React.Fragment>
            <span className="col-sm-2"></span>
            <div className="col-sm-4">
                <img className="car-brand" src={carImage} alt="car" />
                <br />
                {car.brand} {car.model}
            </div>
            <div className="col-sm-4">
                <div className="licence-plate">{car.plateNum}</div>
                {car.color}
            </div>
        </React.Fragment>
    )
}

class CarsPane extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            cars: null
        }
    }

    componentDidMount() {
        if (!this.props.userAuthInfo.user || !this.props.userAuthInfo.user.uid) {
            return
        }
        this.fetchCars()
    }

    fetchCars = () => {
        this.props.firebase
            .fetchCarsForUserId(this.props.userAuthInfo.user.uid)
            .then(cars => {
                this.setState({ cars: cars })
            })
            .catch(error => {
                console.warn("There was an error fetching cars: ", error)
            })
    }

    deleteCarConfirm = carId => {
        return MySwal.fire({
            icon: "question",
            text: this.props.intl.formatMessage({ id: "profile.delete_car" }),
            showCancelButton: true
        }).then(result => {
            if (result.isConfirmed) {
                return this.deleteCar(carId)
            }
        })
    }

    deleteCar = carId => {
        this.props.firebase
            .deleteCarForUserId(carId, this.props.userAuthInfo.user.uid)
            .then(res => {
                this.fetchCars()
                if (this.props.userAuthInfo.forceUpdate) {
                    this.props.userAuthInfo.forceUpdate(this.props.userAuthInfo.user.uid)
                }
            })
            .catch(error => {
                this.setState({ error })
            })
    }

    render() {
        if (!this.state.cars) {
            return <div className="loader"></div>
        }
        return (
            <div className="profile-tab">
                <h2>
                    <FormattedMessage id="profile.my_cars" />
                </h2>
                {this.state.cars.map(car => (
                    <React.Fragment key={car.id}>
                        <div className="row margin">
                            <Car car={car} />
                            <div className="col-sm-2 hm-trash" onClick={e => this.deleteCarConfirm(car.id)}></div>
                        </div>
                        <hr />
                    </React.Fragment>
                ))}
                <div className="row margin">
                    <span className="col-sm-12">
                        <Link to={ROUTES.CARS}>
                            <button type="button" className="btn hm-btn btn-primary">
                                <FormattedMessage id="profile.add_new_car" />
                            </button>
                        </Link>
                    </span>
                </div>
            </div>
        )
    }
}
const CarsPaneFirebase = injectIntl(withFirebase(CarsPane))

const UserProfileBase = ({ userAuthInfo, isCarpooling }) => (
    <React.Fragment>
        <ProfilePane user={userAuthInfo.user} />
        {!isCarpooling ? (
            <>
                <br />
                <div className="profile-tab">
                    <h2 className="mb-4">Integrations</h2>
                    <a href="https://slack.com/oauth/v2/authorize?scope=chat%3Awrite&amp;client_id=609999784001.3962879066695">
                        <button type="button" className="btn hm-btn btn-primary">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ height: "20px", width: "20px", marginRight: "12px" }}
                                viewBox="0 0 122.8 122.8"
                            >
                                <path
                                    d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
                                    fill="#e01e5a"
                                ></path>
                                <path
                                    d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
                                    fill="#36c5f0"
                                ></path>
                                <path
                                    d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
                                    fill="#2eb67d"
                                ></path>
                                <path
                                    d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
                                    fill="#ecb22e"
                                ></path>
                            </svg>
                            Add to Slack
                        </button>
                    </a>
                </div>
            </>
        ) : (
            ""
        )}
        <br />
        {isCarpooling ? (
            <React.Fragment>
                <PointsPaneFirebase userAuthInfo={userAuthInfo} />
                <br />
            </React.Fragment>
        ) : (
            ""
        )}
        <CarsPaneFirebase userAuthInfo={userAuthInfo} />
    </React.Fragment>
)

const Profile = ({ isCarpooling }) => (
    <div className="caption-container">
        <h3>
            <FormattedMessage id="profile.profile" />
        </h3>
        <hr className="d-none d-md-block" />
        <UserStateCheck>
            {userInfo => (
                <Fade>
                    <div className="center-container">
                        <UserProfileBase userAuthInfo={userInfo} isCarpooling={isCarpooling} />
                    </div>
                </Fade>
            )}
        </UserStateCheck>
    </div>
)

export default Profile
