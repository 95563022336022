import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Fade } from 'react-awesome-reveal';

import Firebase, { withFirebase, User, NewRide, Route, RidesPair, Match } from 'components/Firebase';
import * as ROUTES from 'constants/routes';
import { FormattedMessage as FM, FormattedDate, UnsupportedFormatterError } from 'react-intl';
import { UserStateCheck } from 'shared-components/Session';

import 'styles/rides.scss';

import { MatchCell } from 'components/App/rideCells';
import moment from 'moment';
import React from 'react';
import rides from './rides';
import { Direction } from 'components/Firebase/models/other';


interface MatchInstance {
	date: Date
	matches: {match?: Match, rides: RidesPair}[]
}

interface IConnectionsProps {
	firebase: Firebase
	user?: User
}

const ConnectionsBase = (props: IConnectionsProps) => {
	const [matchesByDate, setMatchesByDate] = useState<MatchInstance[] | undefined>(undefined)

	useEffect(() => {
		fetchData()
	}, [])

	const fetchData = async () => {
		const user = props.user
		if (!user || !user.activeLocation) {
			return
		}

		try {
			const startDate = moment.tz(user.activeLocation.timezone).startOf('d').toDate()
			const endDate = moment(startDate).add(2, 'weeks').subtract(1, 'd').endOf('d').toDate()
			//const endDate = moment(startDate).endOf('d').toDate()

			// Fetch passenger routes
			const passengerRoutes = (await (await props.firebase.fetchRoutes(user.uid))).filter(r => !r.canceled)
			passengerRoutes.forEach(r => r.driver = user)

			// For passenger routes fetch matches and ride instances
			let [matches, passengerRideInstances] = await Promise.all([
				props.firebase.fetchMatchesForRoutes(passengerRoutes),
				props.firebase.fetchRideInstancesForRoutes(passengerRoutes, startDate, endDate)
			])

			// For matches fetch driver routes, fill drivers
			const driverRouteIds = Array.from(new Set(matches.map(m => m.driverRouteId)))
			let driverRoutes = await Promise.all(driverRouteIds.map(props.firebase.fetchRoute))
			driverRoutes = driverRoutes.filter(r => r.canDrive)
			await props.firebase.fillRoutesDrivers(driverRoutes)

			// Fetch driver routes instances
			const driverRideInstances =  await props.firebase.fetchRideInstancesForRoutes(driverRoutes, startDate, endDate)

			// Expand and filter rides
			const passengerRides = props.firebase.expandAndFilterRoutes(user.activeLocation!, passengerRoutes, passengerRideInstances, startDate, endDate)
			const driverRides = props.firebase.expandAndFilterRoutes(user.activeLocation!, driverRoutes, driverRideInstances, startDate, endDate)

			// For each day, filter possible driver rides and match them with other direction
			const matchesByDate: MatchInstance[] = []
			const daysCount = moment(endDate).diff(startDate, 'days')
			const firstDayMoment = moment.tz(startDate, user.activeLocation.timezone).startOf('d')
			for (let i=0; i<=daysCount; i++) {
				const startOfDay = moment(firstDayMoment).add(i, "days")
				const endOfDay = moment(startOfDay).endOf('d')

				const passengerRidesForDay = passengerRides.filter(ride => ride.destDatetime >= startOfDay.toDate() && ride.destDatetime <= endOfDay.toDate())
				const driverRidesForDay = driverRides.filter(ride => ride.destDatetime >= startOfDay.toDate() && ride.destDatetime <= endOfDay.toDate())

				// Filter out only possible driver rides (that have non-canceled passenger ride -> match -> driverRide)
				let possibleDriverRides: NewRide[] = []
				let dayMatches: Match[] = []
				for (const passengerRide of passengerRidesForDay) {
					const matchesForPassengerRide = matches.filter(match =>
						match.passengerRouteId === passengerRide.routeId &&
						match.direction === passengerRide.direction
					)

					const driverRidesForPassengerRide = driverRidesForDay.filter(ride =>
						matchesForPassengerRide.find(match =>
							match.driverRouteId === ride.routeId &&
							match.direction === ride.direction
						) !== undefined
					)
					possibleDriverRides = [...possibleDriverRides, ...driverRidesForPassengerRide]
					dayMatches = [...dayMatches, ...matchesForPassengerRide]
				}

				// Create driver ride pairs (ride forward and backward)
				const driverRidesPairs: RidesPair[] = []
				for (const driverRide of possibleDriverRides) {
					let pair = driverRidesPairs.find(pair => pair.routeId === driverRide.routeId)
					if (!pair) {
						pair = new RidesPair(driverRide)
						driverRidesPairs.push(pair)
					} else {
						pair.addRide(driverRide)
					}
				}

				// For each driver rides pair and match create presentable data model
				const matchesData: {match?: Match, rides: RidesPair}[] = []
				driverRidesPairs.map(ridesPair => {
					// Find match, prefer forward direction
					const match = dayMatches.find(match => match.driverRouteId === ridesPair.routeId && (ridesPair.forward ? match.direction === Direction.Forward : true))
					matchesData.push({
						match: match,
						rides: ridesPair
					})
				})

				if (matchesData.length > 0) {
					matchesData.sort((a, b) => (a.match?.mismatchRating ?? 0) - (b.match?.mismatchRating ?? 0))
					matchesByDate.push({date: startOfDay.toDate(), matches: matchesData})
				}
			}

			setMatchesByDate(matchesByDate)
		} catch (error) {
			console.warn("Getting and processing possible connections failed: ", error);
			setMatchesByDate([])
		}
	}

	const user = props.user
	if (!matchesByDate || !user || !user.activeLocation) {
		return (
			<>
				<br />
				<div className = "loader"></div>
			</>
		)
	}

	if (matchesByDate.length === 0) {
		return (
			<>
				<br />
				<h5>
					<FM id="find.no_routes_yet" />
				</h5>
				<br />
				<div className="ride">
					<p><FM id="find.no_possible_connections" /></p>
					<Link to={ROUTES.OFFER}>
						<button type="button" className="hm-btn btn btn-primary"><FM id="nav.create_route" /></button>
					</Link>
				</div>
			</>
		)
	}

	return (
		<>
			{matchesByDate.map(matchByDate =>
			<React.Fragment key={matchByDate.date.getTime()}>
				<div className='text-start'>
					<h4>
						<FormattedDate value={matchByDate.date} weekday="long" month="long" day="numeric" />
					</h4>
				</div>
				{matchByDate.matches.map(matchData => {
					return (
						<Fade key={matchData.rides.forward?.id ?? matchData.rides.backward!.id}>
							<MatchCell match={matchData.match} rides={matchData.rides} />
						</Fade>
					)
				})}
			</React.Fragment>
			)}
		</>
	)
}

const Connections = (props: any) =>
    <div className="caption-container">
        <h3><FM id="nav.possible_connections" /></h3>
        <hr className="d-none d-md-block" />
        <UserStateCheck>
            {userInfo =>
				<>
				<div className="center-container">
					<ConnectionsBase {...props} user={userInfo.user} />
				</div>
				</>
            }
        </UserStateCheck>
    </div>

export default withFirebase(Connections)