import React from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact, {Coords} from 'google-map-react';
import Supercluster from 'supercluster';
import queryString from 'query-string'

import Firebase, {withFirebase, User, Route } from 'components/Firebase';
//import * as ROUTES from 'constants/routes';
import Config from 'constants/config'
import { AuthUserContext } from 'shared-components/Session';

import 'styles/rides.scss';

import * as ROUTES from 'constants/routes';

import rideStart from 'assets/icons/ride-start.svg';
import rideFinish from 'assets/icons/ride-finish.svg';

interface IMapMarkerProps {
    rideStart: boolean
    $hover?: boolean
    lat: number
    lng: number
    text: string
    showBallon?: boolean
    rideId: string
}
class MapMarker extends React.Component<IMapMarkerProps> {
    static propTypes = {
        // GoogleMap pass $hover props to hovered components
        // to detect hover it uses internal mechanism, explained in x_distance_hover example
        $hover: PropTypes.bool,
        text: PropTypes.string,
        zIndex: PropTypes.number
    };

    render() {
        const style = {backgroundColor: "#9DF894"}
        const rideIcon = this.props.rideStart ? rideStart : rideFinish
        const additionalStyle = this.props.$hover ? "map-marker-hover" : ""
        return (
        <div className={"map-marker " + additionalStyle} style={style}>
            <img className="map-marker-image" style={style} src={rideIcon} alt="Reduce traffic" />
        </div>
        )
    }
}

interface IClusterMarkerProps {
    $hover?: boolean
    lat: number
    lng: number
    text: string
}
class ClusterMarker extends React.Component<IClusterMarkerProps> {
    static propTypes = {
        // GoogleMap pass $hover props to hovered components
        // to detect hover it uses internal mechanism, explained in x_distance_hover example
        $hover: PropTypes.bool,
        text: PropTypes.string,
        zIndex: PropTypes.number
    };

    render() {
        const additionalStyle = this.props.$hover ? "map-marker-hover" : ""
        return (
        <div className={"map-marker " + additionalStyle}>
            {this.props.text}
        </div>
        )
    }
}

interface IMapViewState {
    routes?: Route[]
    zoom: number
    bounds: any
    googleApiLoaded: boolean
}
interface IMapViewProps {
    firebase: Firebase
    center: Coords
    defaultZoom: number
    router: any
    user?: User
}

class MapView extends React.Component<IMapViewProps, IMapViewState> {
    supercluster = new Supercluster({radius: 40, maxZoom: 16});
    map: any
    maps: any
    rideLines?: any[]

    constructor(props: IMapViewProps) {
        super(props)

        this.state = {
            routes: undefined,
            zoom: 10,
            bounds: undefined,
            googleApiLoaded: false
        }
    }

    componentDidMount() {
        //For unknown reason, fetchRides sometimes fails without an error
        //It is caused by line return Promise.all(userPromises).then(docs => { in fetchUsers method not returning
        //This delay probably helps it
        this.fetchRoutes()
        this.startTimer()
    }

    static defaultProps = {
        center: {
            lat: 49.145110,
            lng: 16.308193
        },
        defaultZoom: 11,
    };

    fetchRoutes = async () => {
        let visibleCompanies = this.props.user?.visibleCompanies

        const values = queryString.parse(this.props.router.location.search)
        if (values.comp && typeof values.comp === "string") {
            visibleCompanies = [values.comp as string]
        } else if (values.comp && values.comp instanceof Array) {
            visibleCompanies = values.comp.filter(v => v !== null) as string[]
        }

        try {
            const routes = await (await this.props.firebase.fetchRoutes(undefined, visibleCompanies)).filter(r => !r.canceled)
            this.setState({
                routes: routes.filter(r => !r.canceled)
            })
        } catch (error) {
            console.log("Getting routes for map failed: ", error);
        }
    };

    startTimer = () => {
        setInterval(() => this.fetchRoutes(), 1000 * 60 * 10);
    }

    parseRouteToFeature = (route: Route): Supercluster.PointFeature<Supercluster.AnyProps>[] => {
        return [{
            "type": "Feature",
            "properties": {
                "cluster": false,
                "rideId": route.id,
                "title": route.title,
                "rideStart": true
            },
            "geometry": { "type": "Point", "coordinates": [route.origin.lng, route.origin.lat] }
        }, {
            "type": "Feature",
            "properties": {
                "cluster": false,
                "rideId": route.id,
                "title": route.title,
                "rideStart": false
            },
            "geometry": { "type": "Point", "coordinates": [route.destination.lng, route.destination.lat] }
        }]
    };

    handleApiLoaded = (map: any, maps: any) => {
        this.map = map
        this.maps = maps

        this.setState({googleApiLoaded: true})

        this.renderRouteLines()
    };

    renderRouteLines = () => {
        if (this.map && this.maps) {
            this.rideLines?.forEach((rideLine: any) => {
                rideLine.setMap(null)
            })
            this.rideLines = []
            this.state.routes?.forEach(route => {
                let path = [{lat: route.origin.lat, lng: route.origin.lng},
                            {lat: route.destination.lat, lng: route.destination.lng}]
                this.renderLine(path)
            })
        }
    }

    renderLine = (path: any) => {
        let rideLine = new this.maps.Polyline({
            path: path,
            geodesic: false,
            strokeColor: '#33058D',
            strokeOpacity: .03,
            strokeWeight: 8
        })
        this.rideLines?.push(rideLine)
        rideLine.setMap(this.map)
    };

    onChildClick = (key: any, childProps: any) => {
        //this.props.onCenterChange([childProps.lat, childProps.lng]);
        /*const keyNum = parseInt(key)
        if (!isNaN(keyNum)) {
            try {
                const leaves = this.supercluster.getLeaves(keyNum, Infinity)
                let rideIds = new Set<string>()
                leaves.forEach(leaf => {
                    const rideId = leaf.properties && leaf.properties.rideId
                    const rideDatetime = leaf.properties && leaf.properties.rideDatetime
                    if (rideId && rideDatetime) {
                        rideIds.add(rideId+":"+rideDatetime.getTime()/1000)
                    }
                })

                const search = '?rides=' + Array.from(rideIds).join(",")
                this.props.router.navigate(ROUTES.RIDES + search)
            } catch (error) {
                console.warn(error)
            }
        } else {
            let id = childProps.rideId
            if (id) {
                if (id[0] === RideType.Recurring)
                    id += "/" + childProps.datetime.getTime()/1000
                this.props.router.navigate("/app/rides/" + id)
            }
        }*/
    };

    render() {
        let markers: any[] = []
        if (this.state.bounds && this.state.routes && this.state.googleApiLoaded) {
            this.supercluster.load(this.state.routes.flatMap(this.parseRouteToFeature))
            const clusters = this.supercluster.getClusters(this.state.bounds, this.state.zoom)

            markers = clusters.map(cluster => {
                const [lng, lat] = cluster.geometry.coordinates
                if (cluster.properties.cluster) {
                    return <ClusterMarker
                        lat={lat}
                        lng={lng}
                        text={`${cluster.properties.point_count}`}
                        key={cluster.properties.cluster_id}
                    />
                } else {
                    return <MapMarker
                        lat={lat}
                        lng={lng}
                        text={cluster.properties.sourceName}
                        showBallon={true}
                        key={cluster.properties.rideId + (cluster.properties.rideStart ? "_start" : "_end")}
                        rideStart={cluster.properties.rideStart}
                        rideId={cluster.properties.rideId}
                    />
                }
            })

            this.renderRouteLines()
        }

        let center = this.props.center
        const values = queryString.parse(this.props.router.location.search)
        const lat = parseFloat(values.lat as string)
        const lng = parseFloat(values.lng as string)
        if (lat && lng) {
            center = {lat, lng}
        }

        return (
        <React.Fragment>
        {this.state.routes ? "" : <div className="loader map-loader" />}
        <div id="rides-map">
            <GoogleMapReact
                bootstrapURLKeys={{ key: Config.mapsApiKey!}}
                defaultCenter={center}
                defaultZoom={this.props.defaultZoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
                onChildClick={this.onChildClick}
                hoverDistance={10}
                onChange={({ zoom, bounds }) => {
                    this.setState({
                        zoom: zoom,
                        bounds: [bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]
                    })
                }}
            >
            {markers}
            </GoogleMapReact>
        </div>
        </React.Fragment>
        )
    }
};

const MapViewBase = (props: any) => (
    <AuthUserContext.Consumer>
        {userInfo =>
            <MapView {...props} user={userInfo.user} />
        }
    </AuthUserContext.Consumer>
)

export default withRouter(withFirebase(MapViewBase))

import {
    useLocation,
    useNavigate,
    useParams,
  } from "react-router-dom";

function withRouter(Component: any) {
    function ComponentWithRouterProp(props: any) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
        <Component
        {...props}
        router={{ location, navigate, params }}
        />
    );
    }

    return ComponentWithRouterProp;
  }