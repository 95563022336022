import React, { FunctionComponent, useState } from 'react';
import { Link } from "react-router-dom";
import { SegmentedControl } from 'segmented-control';
import { Fade } from 'react-awesome-reveal';
import { Collapse } from 'react-collapse';

import * as ROUTES from 'constants/routes';
import Firebase, { withFirebase, ApiErrorCode, Ride, User, Connection, RidesPair, NewRide } from 'components/Firebase';
import { UserStateCheck } from 'shared-components/Session';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import moment from 'moment';

import 'styles/rides.scss';

import {MatchCell, RideCell} from 'components/App/rideCells'

export enum Page {
    Driver = "d",
    Passenger = "p"
};

interface IRidesBlockProps {
	rides: Array<Ride | RidesPair> | null
	descending?: boolean
	isTemplate?: boolean
	isCollapsed?: boolean
	title: JSX.Element
	children?: React.ReactNode
}
export const RidesBlock: FunctionComponent<IRidesBlockProps> = props => {
	const [isCollapsed, setIsCollapsed] = useState(props.isCollapsed ?? false)

	let body: JSX.Element | JSX.Element[]
	if (props.rides === null) {
		body = <div className = "loader"></div>
	} else if (props.rides.length === 0) {
		body = <div>{props.children}</div>
	} else {
		props.rides.sort((a, b) => {
			const aDate = (a as any).datetime ?? (a as any).forward?.destDatetime ?? (a as any).backward?.destDatetime
			const bDate = (b as any).datetime ?? (b as any).forward?.destDatetime ?? (a as any).backward?.destDatetime
			return (bDate.getTime() - aDate.getTime()) * (props.descending ? -1 : 1)
		})
		let key = 0
		body = props.rides.map(ride => {
			key = key+1
			if (ride instanceof RidesPair) {
				return (
					<Fade key={key}>
						<MatchCell rides={ride} />
						{/*<RideCell ride={ride.asLegacyRide(ride.route!)} newRide={ride} isTemplate={props.isTemplate}/>*/}
					</Fade>
				)
			} else {
				return (
					<Fade key={key}>
						<RideCell ride={ride}/>
					</Fade>
				)
			}
		})
	}
	return (
		<div className="my-3">
			<h3 className="rides-block-title" onClick={() => setIsCollapsed(!isCollapsed)}>
				{props.title} {isCollapsed?<ExpandArrow />:<CollapseArrow />}
			</h3>
			<Collapse isOpened={!isCollapsed}>
				{body}
			</Collapse>
		</div>
	)
}

const CollapseArrow = () =>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrows-collapse" viewBox="0 0 16 16">
		<path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 8zm7-8a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 4.293V.5A.5.5 0 0 1 8 0zm-.5 11.707l-1.146 1.147a.5.5 0 0 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 11.707V15.5a.5.5 0 0 1-1 0v-3.793z"/>
</svg>
const ExpandArrow = () =>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrows-expand" viewBox="0 0 16 16">
	<path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 8zM7.646.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 1.707V5.5a.5.5 0 0 1-1 0V1.707L6.354 2.854a.5.5 0 1 1-.708-.708l2-2zM8 10a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 14.293V10.5A.5.5 0 0 1 8 10z"/>
</svg>

interface IDriverRidesProps {
    firebase: Firebase
    user: User
}
interface IDriverRidesState {
	singleRides: Ride[] | null
	exceptionRides: Ride[] | null
	expandedPairs: RidesPair[] | null
	futureRidesVisible: boolean
}
class DriverRides extends React.Component<IDriverRidesProps, IDriverRidesState> {
	constructor(props: IDriverRidesProps) {
		super(props)

		this.state = {
			singleRides: null,
			exceptionRides: null,
			expandedPairs: null,
			futureRidesVisible: false
		};
	}

  	componentDidMount() {
  		this.fetchDriverRides()
		this.fetchAndExpandMyRoutes()
  	}

	fetchDriverRides = () => {
		this.props.firebase.fetchSingleRides(undefined, this.props.user.uid).then(rides => {
			this.setState({singleRides: rides})
		}).catch((error) => {
			console.warn("Getting single rides failed: ", error)
		})

		this.props.firebase.fetchExceptionRides(undefined, this.props.user.uid).then(rides => {
			this.setState({exceptionRides: rides})
		}).catch((error) => {
			console.warn("Getting exception rides failed: ", error)
		})
	}

	fetchAndExpandMyRoutes = async () => {
		const user = this.props.user
		if (!user || !user.activeLocation) {
			return
		}

		const startDate = moment.tz(user.activeLocation.timezone).startOf('d').toDate()
		const endDate = moment(startDate).add(2, 'weeks').subtract(1, 'd').endOf('d').toDate()

		try {
			let routes = await (await this.props.firebase.fetchRoutes(user.uid))
			routes.forEach(r => r.driver = user)
			routes = routes.filter(r => !r.canceled)

			const rideInstances = await this.props.firebase.fetchRideInstancesForRoutes(routes, startDate, endDate)
			const pairs = this.props.firebase.expandAndFilterRoutesInPairs(user.activeLocation!, routes, rideInstances, startDate, endDate)

			this.setState({expandedPairs: pairs})
		} catch (error) {
			console.warn("Getting and expanding routes failed: ", error)
		}
	}

	futureRidesData= () => {
		if (this.state.singleRides === null || this.state.exceptionRides === null || this.state.expandedPairs === null) {
			return null
		}
		const allRides: Array<Ride | RidesPair> = [...this.state.expandedPairs]
		const currentTimestamp = new Date()
		const legacyRides: Ride[] = [...this.state.singleRides, ...this.state.exceptionRides]
			.filter(ride => {return ride.datetime.getTime() >= currentTimestamp.getTime()})
		for (const ride of legacyRides) {
			if (this.state.expandedPairs.find(pair => pair.forward?.id === ride.id || pair.backward?.id === ride.id) === undefined) {
				allRides.push(ride)
			}
		}

		return allRides
	}

	pastRidesData= () => {
		if (this.state.singleRides === null || this.state.exceptionRides === null) {
			return null
		}
		const currentTimestamp = new Date()
		const allRides = [...(this.state.singleRides || []), ...(this.state.exceptionRides || [])]
			.filter(ride => {return ride.datetime.getTime() < currentTimestamp.getTime()})
		return allRides
	}

	render() {
		return (
			<React.Fragment>
				<RidesBlock rides={this.futureRidesData()} descending={true} title={<FormattedMessage id="my_rides.future_rides"/>}>
					<FormattedMessage id="my_rides.no_planned_rides_driver"/>
					<br />
					<Link to={ROUTES.OFFER}>
                		<button type="button" className="hm-btn btn btn-primary"><FormattedMessage id="find.offer_a_ride"/></button>
            		</Link>
				</RidesBlock>

				<RidesBlock rides={this.pastRidesData()} title={<FormattedMessage id="my_rides.past_rides"/>} isCollapsed={true}>
					<FormattedMessage id="my_rides.no_past_rides_driver"/>
				</RidesBlock>
			</React.Fragment>
		)
	}
}

interface IPassengerRidesProps {
    firebase: Firebase
    user: User
}
interface IPassengerRidesState {
	rides: Ride[] | null
}
class PassengerRides extends React.Component<IPassengerRidesProps, IPassengerRidesState> {
	constructor(props: IPassengerRidesProps) {
		super(props)

		this.state = {
			rides: null,
		};
	}

  	componentDidMount() {
  		this.fetchPassengerRides()
  	}

	fetchPassengerRides = () => {
    	this.props.firebase.fetchPassengerConnections(this.props.user.uid).then((connections): Promise<null | (Ride | null)[]> => {
    		if (connections.length === 0) {
				return Promise.resolve(null)
    		} else {
	    		return Promise.all(connections.map(c => this.fetchRideForConnection(c)))
    		}
    	}).then(result => {
			if (result === null) {
				this.setState({rides: []})
			} else {
				const rides = result.filter(r => r !== null) as Ride[]
				this.setState({rides})
			}
    	}).catch((error) => {
			console.warn("Getting connection failed: ", error);
		})
	};

	fetchRideForConnection = (connection: Connection) => {
		const fs = this.props.firebase
		const promise = connection.isRecurring ? fs.fetchExceptionRide(connection.rideId) : fs.fetchSingleRide(connection.rideId)
		return promise.catch(error => {
			if (error?.code !== ApiErrorCode.NoRide) {
				console.warn("Getting ride failed: ", error);
			}
			return Promise.resolve(null)
		})
	};

	futureRidesData= () => {
		if (this.state.rides === null) {
			return null
		}
		const currentTimestamp = new Date()
		const allRides = (this.state.rides || []).filter(ride => {return ride.datetime.getTime() >= currentTimestamp.getTime()})
		return allRides
	};

	pastRidesData= () => {
		if (this.state.rides === null) {
			return null
		}
		const currentTimestamp = new Date()
		const allRides = (this.state.rides || []).filter(ride => {return ride.datetime.getTime() < currentTimestamp.getTime()})
		return allRides
	};

	render() {
		return (
			<React.Fragment>
				<RidesBlock rides={this.futureRidesData()} title={<FormattedMessage id="my_rides.future_rides"/>} descending={true}>
					<div><FormattedMessage id="my_rides.no_planned_rides_passenger"/></div>
					<br />
					<Link to={ROUTES.OFFER}>
                		<button type="button" className="hm-btn btn btn-primary"><FormattedMessage id="find.offer_a_ride"/></button>
            		</Link>
				</RidesBlock>

				<RidesBlock rides={this.pastRidesData()} title={<FormattedMessage id="my_rides.your_past_rides"/>} isCollapsed={true}>
					<FormattedMessage id="my_rides.no_past_rides_passenger"/>
				</RidesBlock>
			</React.Fragment>
		)
	}
}

interface IMyRidesBaseProps {
    firebase: Firebase
    user: User
    intl: IntlShape
}
interface IMyRidesBaseState {
	rides: Ride[] | null
	selectedPage: Page
}
class MyRidesBase extends React.Component<IMyRidesBaseProps, IMyRidesBaseState> {
	constructor(props: IMyRidesBaseProps) {
		super(props)

		this.state = {
			rides: null,
			selectedPage: Page.Driver
		};
	}

	pageSelected = (newValue: Page) => {
		this.setState({selectedPage: newValue})
	};

	render() {
		const body = this.state.selectedPage === Page.Driver ?
			<DriverRides firebase={this.props.firebase} user={this.props.user} /> :
			<PassengerRides firebase={this.props.firebase} user={this.props.user} />

		return (
			<div className="center-container">
				<SegmentedControl
					name="Role select"
					options={[
						{ label: this.props.intl.formatMessage({id: 'my_rides.driver'}), value: Page.Driver, default: true },
						{ label: this.props.intl.formatMessage({id: 'my_rides.passenger'}), value: Page.Passenger }
					]}
					setValue={this.pageSelected}
					style={{transitionDuration: "0.5s", borderRadius: "0.75rem", width: "70%"}}
				/>
				<br />
				{body}
			</div>
		)
	}
}

const MyRides = (props: any) => (
    <div className="caption-container">
        <h3><FormattedMessage id="nav.my_rides"/></h3>
        <hr className="d-none d-md-block" />
        <UserStateCheck>
            {userInfo =>
                <Fade><MyRidesBase user={userInfo.user} {...props} /></Fade>
            }
        </UserStateCheck>
    </div>
	)

export default injectIntl(withFirebase(MyRides));
