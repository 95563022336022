import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import CookieConsent from "react-cookie-consent";
import TagManager from 'react-gtm-module'
import { Link } from "react-router-dom";
import {FormattedMessage} from 'react-intl';

//Intersection observer - this might be removed with later versions of Safari
import 'intersection-observer/intersection-observer.js'

import {gtmConfig} from 'constants/config.js'
import * as ROUTES from "constants/routes";

import 'semantic-ui-css/semantic.min.css';
import "styles/App.scss";
import "styles/font.css";
import "styles/commonStyle.scss";

import Navigation from "components/Main/navigation"
//import Footer from "components/Main/footer.js"
import NotFound from "components/Main/notFound.js"

//import Home from "components/home.js"

import Rides from "components/App/rides"
import FindRide from "components/App/find"
import MapView from "components/App/ridesMap"
import MyRides from "components/App/myRides"
import Connections from "components/App/connections"
import MyRoutes from "components/App/myRoutes"
import RideDetail from "components/App/rideDetail"
import Offer from "components/App/offer"
import Accept from "components/App/accept.js"
import Decline from "components/App/decline.js"
import UserProfile from "components/App/userProfile"

import {withAuthentication} from 'shared-components/Session';
import Firebase, {withFirebase, User, Language } from 'components/Firebase';
import {AuthUserContext, isInWebView} from 'shared-components/Session';

import {IntlProvider} from 'react-intl';
import strings_en from 'shared-components/internationalization/strings_en.json';
import strings_cz from 'shared-components/internationalization/strings_cz.json';
import strings_sk from 'shared-components/internationalization/strings_sk.json';
import strings_hu from 'shared-components/internationalization/strings_hu.json';

import logo from "assets/logo.svg";
import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import muiTheme from 'otherFiles/muiTheme';

const BenefitsPage = lazy(() => import('components/Statics/benefits.js'));
const AboutPage = lazy(() => import('shared-components/Statics/about.js'));
const ContactsPage = lazy(() => import('shared-components/Statics/contacts'));
const ParkingTermsPage = lazy(() => import('components/Statics/parkingTerms.js'));
const HowItWorksPage = lazy(() => import('components/Statics/howItWorks.js'));
const HowItWorksJeduPage = lazy(() => import('components/Statics/howItWorksJedu.js'));
const CompetitionPage = lazy(() => import('components/Statics/competition.js'));
//const CompetitionRules = lazy(() => import('components/Statics/competition-rules.js'));
const FAQPage = lazy(() => import('components/Statics/faq'));

const Admin = lazy(() => import('components/Admin/admin.js'));
const HMAdmin = lazy(() => import('components/Admin/hmAdmin.js'));
const Analytics = lazy(() => import('components/Admin/analytics'));
const UserManagement = lazy(() => import('shared-components/Admin/userManagement'));

const Profile = lazy(() => import('shared-components/User/profile.js'));
const EditProfile = lazy(() => import('shared-components/User/editProfile'));
const SignInPage = lazy(() => import('shared-components/User/signIn.js'));
const SignUpPage = lazy(() => import('shared-components/User/signUp'));
const CreateCars = lazy(() => import('shared-components/User/createCars'));
const UserMgmt = lazy(() => import('shared-components/User/userMgmt.js'));
const ReverifyEmail = lazy(() => import('shared-components/User/reverifyEmail.js'));

const messages = {
    'en': strings_en,
    'cs': strings_cz,
    //'sk': strings_sk,
    //'hu': strings_hu,
};
const i18nConfig = {
    defaultLocale: 'en',
    messages,
};
const languages: Language[] = [
    new Language('cs', '🇨🇿 Česky'),
    //new Language('sk', '🇸🇰 Slovensky'),
    new Language('en', '🇬🇧 English'),
    //new Language('hu', '🇭🇺 Magyar')
]

//Google tag manager
TagManager.initialize(gtmConfig)

interface IAppProps {
    user?: User
    firebase: Firebase
}
interface IAppState {
    browserLanguage: string
    language?: string
}
class App extends React.Component<IAppProps, IAppState> {
    constructor(props: IAppProps){
        super(props);

        const browserLocale = (navigator.languages && navigator.languages[0]) || navigator.language || 'en-US';
        let browserLanguage = browserLocale.split(/[-_]/)[0];
        if (!Object.keys(messages).includes(browserLanguage)) {
            browserLanguage = 'en'
        }

        this.state = {
            browserLanguage: browserLanguage,
            language: undefined
        }
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll = () => {
        if (window.scrollY > 25) {
            document.querySelector("#page-wrap-spacer")!.className = "page-wrap-spacer-solid";
        } else {
            document.querySelector("#page-wrap-spacer")!.className = "page-wrap-spacer-transparent";
        }
    };

    setLanguage = (languageId: string) => {
        this.setState({language: languageId})
        this.props.firebase.setAuthLocale(languageId)
        if (this.props.user?.uid) {
            this.props.firebase.setUserLocale(this.props.user.uid, languageId)
        }
    }

    render() {
    const locale = (this.state.language || (this.props.user && this.props.user.locale) || this.state.browserLanguage) as ('cs' | 'en')
    return(
    <IntlProvider locale={locale} defaultLocale={i18nConfig.defaultLocale} messages={i18nConfig.messages[locale]}>
        <ThemeProvider theme={muiTheme}>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                <BrowserRouter>
                    <Navigation setLanguage={this.setLanguage} languages={languages} selectedLanguageId={locale} />
                    <div id="page-wrap-spacer">
                        <img className="navigation-logo d-md-none" src = {logo} alt="logo"/>
                    </div>
                    <div id="page-wrap">
                        <Suspense fallback={<div className="caption-container"><br /><div className="loader"></div></div>}>
                        <Routes>
                            <Route path="/" element={<Navigate replace to={ROUTES.HOME} />} />
                            <Route path={ROUTES.BENEFITS} element={<BenefitsPage />} />
                            <Route path={ROUTES.ABOUT} element={<AboutPage />} />
                            <Route path={ROUTES.CONTACTS} element={<ContactsPage isCarpooling={true} />}/>
                            <Route path={ROUTES.PARKING_TERMS} element={<ParkingTermsPage />} />
                            <Route path={ROUTES.HOW_IT_WORKS} element={<HowItWorksPage />} />
                            <Route path={ROUTES.HOW_IT_WORKS_JEDU} element={<HowItWorksJeduPage />} />
                            <Route path={ROUTES.COMPETITION} element={<CompetitionPage  />} />
                            {/*<Route path={ROUTES.COMPETITION_RULES} element={CompetitionRules} />*/}
                            <Route path={ROUTES.FAQ} element={<FAQPage />} />

                            <Route path={ROUTES.ADMIN} element={<Admin />} />
                            <Route path={ROUTES.HM_ADMIN} element={<HMAdmin />} />
                            <Route path={ROUTES.ANALYTICS} element={<Analytics />} />
                            <Route path={ROUTES.USER_MANAGEMENT} element={<UserManagement/>} />
                            <Route path={ROUTES.USER_MGMT} element={<UserMgmt />} />
                            <Route path={ROUTES.REVERIFY_EMAIL} element={<ReverifyEmail />} />

                            <Route path={ROUTES.RIDES} element={<Rides />} />
                            {/*<Route path={ROUTES.FIND_RIDE} element={<FindRide />} />*/}
                            <Route path={ROUTES.RIDES_MAP} element={<div className="rides-map-container"><MapView /></div>} />
                            <Route path={ROUTES.MY_RIDES} element={<MyRides />} />
                            <Route path={ROUTES.CONNECTIONS} element={<Connections />} />
                            <Route path={ROUTES.MY_ROUTES} element={<MyRoutes />} />
                            <Route path={ROUTES.RIDE_DETAIL} element={<RideDetail />} />
                            <Route path={ROUTES.RIDE_DETAIL_DATETIME} element={<RideDetail />} />
                            <Route path={ROUTES.OFFER} element={<Offer />} />
                            <Route path={ROUTES.ACCEPT} element={<Accept />} />
                            <Route path={ROUTES.DECLINE} element={<Decline />} />
                            <Route path={ROUTES.USER_PROFILE} element={<UserProfile />} />

                            <Route path={ROUTES.PROFILE} element={<Profile isCarpooling={true} />}/>
                            <Route path={ROUTES.EDIT_PROFILE} element={<EditProfile />} />
                            <Route path={ROUTES.SIGN_IN} element={<SignInPage />} />
                            <Route path={ROUTES.SIGN_UP} element={<SignUpPage locale={locale} />}/>
                            <Route path={ROUTES.CARS} element={<CreateCars />} />

                            <Route path="*" element={<NotFound />} />
                        </Routes>
                        </Suspense>
                    </div>
                    {/*<Footer />*/}

                    {isInWebView() ? <div></div> :
                    <CookieConsent
                        //acceptOnScroll={true}
                        //acceptOnScrollPercentage={50}
                        buttonStyle={{ background: "#9DF894", color: "#353535"}}
                        buttonText={<FormattedMessage id="general.understand" />}
                    >
                        <FormattedMessage id="general.cookie_notice" values={{ link: (label) => <Link to='https://www.yedem.io/pp'>{label}</Link> }} />
                    </CookieConsent>
                    }
                </BrowserRouter>
            </LocalizationProvider>
        </ThemeProvider>
    </IntlProvider>
    )}
}

interface IAppBaseProps {
    firebase: Firebase
}
class AppBase extends React.Component<IAppBaseProps, any> {
    render() {
    return (
        <AuthUserContext.Consumer>
            {userInfo => {
                return <App user={userInfo.user} {...this.props} />
            }}
        </AuthUserContext.Consumer>
    )};
}

export default withFirebase(withAuthentication(AppBase));