import React from 'react';
import { Fade } from 'react-awesome-reveal';

import Firebase, {withFirebase, Ride, User } from 'components/Firebase';
import { UserStateCheck } from 'shared-components/Session';
import {FormattedMessage as FM, injectIntl, IntlShape} from 'react-intl';

import { InfoCell } from 'shared-components/User/profile.js'
import { RidesBlock } from 'components/App/myRides'

import 'styles/rides.scss';
import 'styles/userProfile.scss';

interface IUserProfileBaseProps {
    firebase: Firebase
    router: any
    intl: IntlShape
}
interface IUserProfileBaseState {
    user: User | null | undefined
    rides: Ride[] | null
}
class UserProfileBase extends React.Component<IUserProfileBaseProps, IUserProfileBaseState> {
    isInstanceOfRecurring: boolean = false //determins if is generated recurring ride instance
	constructor(props: IUserProfileBaseProps) {
		super(props)

		this.state = {
			user: undefined,
            rides: null
		}
	}

    componentDidMount() {
        this.fetchUser()
        this.fetchRides()
    }

    fetchUser = () => {
        const userId = this.props.router.params.userId ?? ""
        this.props.firebase.fetchUserData(userId).then(user => {
            this.setState({user})
        })
    }

    fetchRides = () => {
        const userId = this.props.router.params.userId ?? ""
        this.props.firebase.fetchAllRides(new Date(), userId).then(rides => {
            this.setState({rides})
        })
    }


    render() {
    	if (this.state.user === undefined) {
    		return <div className = "loader"></div>
        }
        if (this.state.user === null) {
            return <p className="error">Loading user failed</p>
        }

        return (
            <div className="center-container">
                <div className = "profile-tab">
                    <h3><FM id="user_profile.user_details"/></h3>
                    <InfoCell cellTitle={<FM id="profile.name"/>} cellValue={this.state.user.name + " " + this.state.user.surname.substring(0, 1)} />
                </div>
                <br />
                <RidesBlock rides={this.state.rides?.filter(r => !r.canceled) ?? null} descending={true} title={<FM id="user_profile.user_rides"/>}/>
            </div>
        )
    }
};

const UserProfileContainer = (props: any) => (
    <div className="caption-container">
        <h3><FM id="user_profile.user_profile"/></h3>
        <hr className="d-none d-md-block" />
        <UserStateCheck>
            {userInfo =>
                <Fade><UserProfileBase {...props} /></Fade>
            }
        </UserStateCheck>
    </div>
)

const UserProfile = withRouter(injectIntl(withFirebase(UserProfileContainer)));

export default UserProfile

import {
    useLocation,
    useNavigate,
    useParams,
  } from "react-router-dom";

function withRouter(Component: any) {
    function ComponentWithRouterProp(props: any) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
        <Component
        {...props}
        router={{ location, navigate, params }}
        />
    );
    }

    return ComponentWithRouterProp;
  }