const firebaseConfig = process.env.REACT_APP_ENV === 'staging' ?
	{
		apiKey: process.env.REACT_APP_BROWSER_API_KEY,
		mapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
		authDomain: "staging-carpool.yedem.io", //"hitchme-503a5.firebaseapp.com",
		databaseURL: "https://hitchme-503a5.firebaseio.com",
		projectId: "hitchme-503a5",
		storageBucket: "hitchme-503a5.appspot.com",
		messagingSenderId: "106167756655",
		appId: "1:106167756655:web:12735fec08e6e9b7"
	} :
	{
		apiKey: process.env.REACT_APP_BROWSER_API_KEY,
		mapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
		authDomain: "carpool.yedem.io", //"hitch-me.firebaseapp.com",
		databaseURL: "https://hitch-me.firebaseio.com",
		projectId: "hitch-me",
		storageBucket: "hitch-me.appspot.com",
		messagingSenderId: "1058283536856",
		appId: "1:1058283536856:web:9231e61e8a0f4e14"
	};

const gtmConfig = process.env.REACT_APP_ENV === 'staging' ?
	{
		gtmId: 'GTM-PDZBF7S',
		auth: 'JRw6sIAwOVpY0caWL45ACw',
		preview: 'env-6'
	} :
	{
		gtmId: 'GTM-PDZBF7S',
		auth: 'twip67Q10nqdA1eBICMx-A',
		preview: 'env-8'
	};

const Config = {
	mapsApiKey: process.env.REACT_APP_MAPS_API_KEY
}

export {firebaseConfig, gtmConfig};
export default Config;