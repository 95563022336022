import { DocumentSnapshot } from "../firestoreImports.js"
import { Direction } from "./other.js"
import { Route } from "./route.js"

export class Match {
    constructor(
        public id: string,
        public driverRouteId: string,
        public passengerRouteId: string,
        public passengerId: string,
        public detour: number,
        public timeDelta: number,
        public direction: Direction,
        public passengerFavorite: boolean,
        public otherDirectionMatchId?: string,
        public mismatchRating?: number
    ) {}

    // Helpers
    driverRoute?: Route
    passengerRoute?: Route

    static fromData(doc: DocumentSnapshot): Match | null {
        if (!doc.data()) {
            console.warn("Cannot create Match from snapshot")
            return null
        }
        const data = doc.data()!
        const driverRouteId = data.driverRouteId
        const passengerRouteId = data.passengerRouteId
        const passengerId = data.passengerId
        const detour = data.detour
        const timeDelta = data.timeDelta
        const direction = data.direction
        if (!driverRouteId || !passengerRouteId || !passengerId || detour === undefined || timeDelta === undefined || direction === undefined) {
            console.warn("Cannot create Match from snapshot ", data)
            return null
        }
        const passengerFavorite = data.passengerFavorite ?? false
        return new Match(
            doc.id,
            driverRouteId,
            passengerRouteId,
            passengerId,
            detour,
            timeDelta,
            direction,
            passengerFavorite,
            data.otherDirectionMatchId,
            data.mismatchRating
        )
    }

    asData(): { [k: string]: any } {
        const data: { [k: string]: any } = {
            driverRouteId: this.driverRouteId,
            passengerRouteId: this.passengerRouteId,
            passengerId: this.passengerId,
            detour: this.detour,
            timeDelta: this.timeDelta,
            direction: this.direction,
            passengerFavorite: this.passengerFavorite,
            mismatchRating: this.mismatchRating ?? this.detour + Math.abs(this.timeDelta), //Last resort backup calculation
            timestampCreated: new Date()
        }
        if (this.otherDirectionMatchId) {
            data.otherDirectionMatchId = this.otherDirectionMatchId
        }
        return data
    }
}
