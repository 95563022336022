import React from 'react';
import { Link } from "react-router-dom";
import { Fade } from 'react-awesome-reveal';
import queryString from 'query-string'

import Firebase, { withFirebase, Ride, User } from 'components/Firebase';
import * as ROUTES from 'constants/routes';
import {FormattedMessage} from 'react-intl';
import { UserStateCheck } from 'shared-components/Session';

import 'styles/rides.scss';

import {RideCell} from 'components/App/rideCells';

interface IRidesTableState {
	rides?: Ride[]
	sourceFilter?: PlaceFilter
	destFilter?: PlaceFilter
}

interface IRidesTableProps {
	firebase: Firebase
	router: any
	user?: User
}

interface PlaceFilter {
	lat: number
	lng: number
	radius: number
}

class RidesTable extends React.Component<IRidesTableProps, IRidesTableState> {
	constructor(props: IRidesTableProps) {
		super(props)

		this.state = {
			rides: undefined,
			sourceFilter: undefined,
			destFilter: undefined,
		};
	}

  	componentDidMount() {
		const values = queryString.parse(this.props.router.location.search)
		if (values.rides) {
			const rideData = (values.rides as string).split(",")
			this.fetchSpecificRides(rideData)
		} else {
			let sourceFilter: PlaceFilter | undefined = undefined
			if (values.srcLat && values.srcLng && values.srcRadius) {
				sourceFilter = {lat: parseFloat(values.srcLat as  string), lng: parseFloat(values.srcLng as string), radius: parseFloat(values.srcRadius as string)}
			}
			let destFilter: PlaceFilter | undefined = undefined
			if (values.dstLat && values.dstLng && values.dstRadius) {
				destFilter = {lat: parseFloat(values.dstLat as  string), lng: parseFloat(values.dstLng as string), radius: parseFloat(values.dstRadius as string)}
			}

	  		this.setState({
	  			rides: undefined,
	  			sourceFilter: sourceFilter,
	  			destFilter: destFilter
	  		})

	  		this.fetchRides()
  		}
  	}

	fetchRides = () => {
		/*
        this.props.firebase.fetchRidesInRadius(this.props.sourceCoordinates, 20).then(rides => {
			this.setState({
					rides: rides
    		});
		})
		return
		*/

		let visibleCompanies = this.props.user?.visibleCompanies
    	this.props.firebase.fetchAllRides(new Date(), undefined, undefined, undefined, visibleCompanies).then(rides => {
            const activeRides = rides.filter(ride => !ride.canceled)
            this.setState({
                    rides: activeRides
            });
    	}).catch((error) => {
			console.warn("Getting rides(1) failed: ", error);
		})
	};

	fetchSpecificRides = (rideData: string[]) => {
		Promise.all(rideData.map(rideInfo => {
			const [rideId, rideDatetime] = rideInfo.split(":")
			return this.props.firebase.fetchRideWithComposedId(rideId).then(ride => {
				ride.datetime = new Date(parseInt(rideDatetime) * 1000)
				return Promise.resolve(ride)
			})
		})).then(rides => {
    		const activeRides = rides.filter(ride => !ride.canceled)
			this.setState({
				rides: activeRides
    		});
    	}).catch((error) => {
			this.setState({
				rides: []
    		});
			console.warn("Getting rides(2) failed: ", error);
		})
	};

	filterRides = (rides: Ride[]) => {
		return rides.filter(ride => {
			let result = true
			if (this.state.sourceFilter) {
				const latDist = (ride.source.lat - this.state.sourceFilter.lat ) * 110.574
				const lngDist = (ride.source.lng - this.state.sourceFilter.lng ) * 111.320 * Math.cos(ride.source.lat)
				const dist = Math.sqrt(latDist**2 + lngDist**2)
				result = result && dist * 1000 < this.state.sourceFilter.radius
				ride.distance = dist
			}
			if (this.state.destFilter) {
				const latDist = (ride.destination.lat - this.state.destFilter.lat ) * 110.574
				const lngDist = (ride.destination.lng - this.state.destFilter.lng ) * 111.320 * Math.cos(ride.destination.lat)
				const dist = Math.sqrt(latDist**2 + lngDist**2)
				result = result && dist * 1000 < this.state.destFilter.radius
			}
			return result
		})
	};

	render() {
		if (!this.state.rides) {
            return (
            	<React.Fragment>
					<br />
            		<div className = "loader"></div>
            	</React.Fragment>
            )
		}

		const allRides = this.filterRides(this.state.rides)
		if (allRides.length === 0) {
			return (
				<React.Fragment>
					<br />
					<div className="ride">
						<p><FormattedMessage id="find.no_rides" /></p>
						<Link to={ROUTES.OFFER}>
							<button type="button" className="hm-btn btn btn-primary"><FormattedMessage id="find.offer_a_ride" /></button>
						</Link>
					</div>
				</React.Fragment>
			)
		} else {
			allRides.sort((a, b) => { return a.datetime.getTime() - b.datetime.getTime() });
			let key = 0
			const listItems = allRides.map(ride => {
				key = key + 1
				return (
					<React.Fragment key={key}>
						<Fade><RideCell ride={ride} /></Fade>
					</React.Fragment>
				)
			}
			);
			return (
				<React.Fragment>
					{listItems}
				</React.Fragment>
			)
		}
	}
}

const Rides = (props: any) =>
    <div className="caption-container">
        <h3><FormattedMessage id="nav.find_a_ride" /></h3>
        <hr className="d-none d-md-block" />
        <UserStateCheck>
            {userInfo =>
				<>
				<button className="btn btn-outline-primary mb-2" onClick={() => { props.router.navigate(-1)}}>❮</button>
				<div className="center-container">
					<RidesTable {...props} user={userInfo.user} />
				</div>
				</>
            }
        </UserStateCheck>
    </div>

export default withRouter(withFirebase(Rides));

import {
    useLocation,
    useNavigate,
    useParams,
  } from "react-router-dom";

function withRouter(Component: any) {
    function ComponentWithRouterProp(props: any) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
        <Component
        {...props}
        router={{ location, navigate, params }}
        />
    );
    }

    return ComponentWithRouterProp;
  }