import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Fade } from 'react-awesome-reveal';

import Firebase, { withFirebase, User, Route } from 'components/Firebase';
import * as ROUTES from 'constants/routes';
import { FormattedMessage as FM } from 'react-intl';
import { UserStateCheck } from 'shared-components/Session';

import 'styles/rides.scss';

import { RouteCell } from 'components/App/rideCells';

interface IMyRoutesProps {
	firebase: Firebase
	user?: User
}

const MyRoutesBase = (props: IMyRoutesProps) => {
	const [routes, setRoutes] = useState<Route[] | undefined>(undefined)

	useEffect(() => {
		fetchRoutes()
	}, [])

	const fetchRoutes = async () => {
		const user = props.user
		if (!user) {
			return
		}

		try {
			const routes = await props.firebase.fetchRoutes(user.uid)
			const filteredRoutes = routes.filter(r => !r.canceled)
			await props.firebase.fillRoutesDrivers(filteredRoutes)
			setRoutes(filteredRoutes)
		} catch (error) {
			console.warn("Getting routes failed: ", error);
		}
	}

	const setRouteCanceled = async (routeId: string, canceled: boolean) => {
		await props.firebase.updateRoute(routeId, {canceled: canceled})
		fetchRoutes()
	}

	if (routes === undefined) {
		return (
			<>
				<br />
				<div className = "loader"></div>
			</>
		)
	}

	if (routes.length === 0) {
		return (
			<>
				<br />
				<div className="ride">
					<p><FM id="find.no_routes" /></p>
					<Link to={ROUTES.OFFER}>
						<button type="button" className="hm-btn btn btn-primary"><FM id="nav.create_route" /></button>
					</Link>
				</div>
			</>
		)
	}

	return (
		<>
			{routes.map(route =>
					<Fade key={route.id}><RouteCell route={route} setRouteCanceled={setRouteCanceled} /></Fade>
			)}
		</>
	)
}

const MyRoutes = (props: any) =>
    <div className="caption-container">
        <h3><FM id="nav.my_routes" /></h3>
        <hr className="d-none d-md-block" />
        <UserStateCheck>
            {userInfo =>
				<>
				<div className="center-container">
					<MyRoutesBase {...props} user={userInfo.user} />
				</div>
				</>
            }
        </UserStateCheck>
    </div>

export default withFirebase(MyRoutes)