import { useEffect, useState } from 'react';
import moment, { Moment } from'moment'
import 'moment/locale/cs'
import 'styles/react-datetime.css';
import Autocomplete from 'react-google-autocomplete';
import { Fade } from 'react-awesome-reveal';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import withReactContent from 'sweetalert2-react-content'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { ScheduleSelect } from 'shared-components/Components/ScheduleSelect';
import WebAPI from 'components/Firebase/webAPI';
import Firebase, { Route, User, withFirebase } from 'components/Firebase';
import { Place } from 'components/Firebase';
import { UserAuthInfo, UserStateCheck } from 'shared-components/Session';
import { StyledDatePicker, StyledTimePicker } from 'shared-components/Components/StyledDateTimePicker';
import { FormRow} from 'components/common';
import { FormattedMessage, injectIntl } from 'react-intl';

import AddCircleIcon from '@mui/icons-material/AddCircle';

import * as ROUTES from 'constants/routes';
import Config from 'constants/config'

const MySwal = withReactContent(Swal)

interface IOfferRideFormProps {
    firebase: Firebase
    userInfo: UserAuthInfo
    user: User
    router: any
    intl: any
}

const OfferRideForm = (props: IOfferRideFormProps) => {
    // Route data
    const [routeTitle, setRouteTitle] = useState("")
    const [origin, setOrigin] = useState<Place>()
    const [destination, setDestination] = useState<Place>()
    const [singleRideDate, setSingleRideDate] = useState(moment().add(1, 'd'))
    const [destArrival, setDestArrival] = useState<number>(60*60*6)
    const [destDeparture, setDestDeparture] = useState<number>(60*60*16)
    const [canDrive, setCanDrive] = useState(true)
    const [carId, setCarId] = useState<string | undefined>(props.user.cars?.[0]?.id)
    const [maxDetour, setMaxDetour] = useState(10) //minutes!
	const [wantParking, setWantParking] = useState(true)
	const [note, setNote] = useState("")

    // Form state
    const [originName, setOriginName] = useState("")
    const [destinationName, setDestinationName] = useState("")
    const [error, setError] = useState<Error>()
    const [isRecurring, setIsRecurring] = useState(true)
    const [isRoundtrip, setIsRoundtrip] = useState(true)
    const [scheduleValues, setScheduleValues] = useState([0,1,2,3,4])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (!props.userInfo.authUser?.emailVerified) {
            return props.router.navigate(ROUTES.REVERIFY_EMAIL)
        }
    }, [props.userInfo.authUser])

    const createPlace = (place: any): Place => {
        return new Place(
            place.name,
            place.geometry.location.lat(),
            place.geometry.location.lng(),
            place.place_id
        )
    }

    const onOriginChange = (place: any) => {
        if (place && place.geometry) {
            setOrigin(createPlace(place))
        }
    }

    const onDestinationChange = (place: any) => {
        if (place && place.geometry) {
            setDestination(createPlace(place))
        }
    }

    const onArrivalDateChange = (date: Moment | string) => onDateChange(date, setDestArrival)
    const onDepartureDateChange = (date: Moment | string) => onDateChange(date, setDestDeparture)
    const onDateChange = (date: Moment | string, setDatetime: (value: number) => void) => {
        const destDatetime = moment(date).tz(props.user.activeLocation!.timezone)
        if (destDatetime.isValid()) {
            const seconds = destDatetime.diff(destDatetime.clone().startOf('d'), 'seconds')
            setDatetime(seconds)
        }
    }

    const onSingleRideDateChanged = (date: Moment | string) => {
        const datetime = moment(date).tz(props.user.activeLocation!.timezone)
        setSingleRideDate(datetime)
    }

    const onSubmit = async (event: any) => {
        event.preventDefault()

        let errorMsg = ""
        if (!origin) {
            errorMsg += props.intl.formatMessage({id: 'offer.select_source'})
        }
        if (!destination) {
            errorMsg += props.intl.formatMessage({id: 'offer.select_destination'})
        }
        if (destArrival === undefined || (destDeparture === undefined && isRoundtrip)) {
            errorMsg += props.intl.formatMessage({id: 'offer.select_date'})
        }
        if (!carId && canDrive) {
            errorMsg += props.intl.formatMessage({id: 'offer.select_car'})
        }
        if (errorMsg) {
            setError(new Error(errorMsg))
            return
        } else {
            setError(undefined)
        }
        let maxDetourValue = maxDetour
        if (isNaN(maxDetour) || maxDetour < 1 || maxDetour > 60) {
            maxDetourValue = 15
        }

        const localRouteTitle = routeTitle ? routeTitle : props.intl.formatMessage({id: 'offer.name_route_placeholder'})

        const schedule = isRecurring ? scheduleValues.map(val => val+1) : []
        const route = new Route("", localRouteTitle, origin!, destination!, props.user.uid, schedule, -1, props.user.company, canDrive, maxDetourValue*60, carId, destArrival, isRoundtrip ? destDeparture : undefined, note, undefined, false, !wantParking)

        let selectedDays: Date[] = []
        if (!isRecurring) {
            selectedDays = [singleRideDate.toDate()]
        }

        setIsLoading(true)

        try {
            await WebAPI.createRoute(route, selectedDays)

            await MySwal.fire({
                icon: 'success',
                title: props.intl.formatMessage({id: 'offer.ride_posted'}),
            })
            props.router.navigate(ROUTES.MY_ROUTES)
        } catch (error) {
            console.log("Error creating route ", error)
            await MySwal.fire({
                icon: 'error',
                title: props.intl.formatMessage({id: 'offer.route_error'}),
            })
        } finally {
            setIsLoading(false)
        }
    }

    const recurringRidesPopover =
        <Popover id="popover-basic" title="Title">
            <Popover.Body><FormattedMessage id="offer.recurring_tooltip"/></Popover.Body>
        </Popover>

    //const bounds = {north: 49.361079, south: 49.067156, west: 16.417192, east: 16.860313}
    const searchBounds = {north: 50.955538, south: 46.685115, west: 12.145066, east: 19.817759}
    const searchCountryRestriction = ["cz", "sk", "hu"]
    const searchOptions: google.maps.places.AutocompleteOptions = {
        fields: ["name", "geometry.location", "place_id"],
        componentRestrictions: { country: searchCountryRestriction },
        types: [],
        bounds: searchBounds,
        strictBounds: false
    }
    return (
        <div className="center-container">
            {error && error.message && <p className="error">{error.message}</p>}

            <form onSubmit={onSubmit} autoComplete='off'>
                <FormRow label={<FormattedMessage id="offer.name_route"/>} labelFor="routeTitle">
                        <input className="col-sm-4 y-style flex-grow-1" type="text" id="routeTitle" name="routeTitle" placeholder={props.intl.formatMessage({id: 'offer.name_route_placeholder'})} value={routeTitle} onChange={(event) => setRouteTitle(event.target.value)}/>
                </FormRow>

                <FormRow label={<FormattedMessage id="offer.from_where"/>} labelFor="origin">
                    <Autocomplete
                        apiKey={Config.mapsApiKey}
                        className="col-md-4 col-sm-5 y-style"
                        type="text"  id="origin" name = "originName" placeholder = ""
                        value={originName} onChange={(e: any) => setOriginName(e.value)}
                        options={searchOptions}
                        onPlaceSelected={onOriginChange}
                        onKeyPress={(e: any) => {if (e.key === 'Enter') e.preventDefault();}}
                        required
                        inputAutocompleteValue='off'
                    />
                </FormRow>

                <FormRow label={<FormattedMessage id="offer.going_to"/>} labelFor="destination">
                    <Autocomplete
                        apiKey={Config.mapsApiKey}
                        className="col-md-4 col-sm-5 y-style"
                        type="text"  id="destination" name = "destinationName" placeholder = ""
                        value={destinationName} onChange={(e: any) => setDestinationName(e.value)}
                        options={searchOptions}
                        onPlaceSelected={onDestinationChange}
                        onKeyPress={(e: any) => {if (e.key === 'Enter') e.preventDefault();}}
                        required
                        inputAutocompleteValue='off'
                    />
                </FormRow>


                <FormRow><hr className="col-md-4 col-sm-5 p-0"/></FormRow>

                <FormRow label={
                            <span>
                                <FormattedMessage id="offer.recurring"/>
                                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={recurringRidesPopover}>
                                    <span className="tooltip-questionmark">?</span>
                                </OverlayTrigger>
                            </span>
                        } labelFor="repeated">
                    <span className = "col-md-1 col-sm-1 p-0 vertical-align switch-container">
                        <input className="switch mr-2" type="checkbox" id="repeated" name="repeatValue" checked={isRecurring} onChange={(event) => setIsRecurring(event.target.checked)}/>
                    </span>
                </FormRow>

                <FormRow label={<FormattedMessage id="offer.schedule"/>} labelFor="scheduleSelect" hidden={!isRecurring}>
                    <div id="scheduleSelect" className = "col-md-4 col-sm-5 p-0 text-start"><ScheduleSelect initialSelection={scheduleValues} showDate={!isRecurring} onChange={selection => setScheduleValues(selection)} /></div>
                </FormRow>

                <FormRow label={<FormattedMessage id="offer.when"/>} labelFor="datetimePicker1" hidden={isRecurring}>
                    <StyledDatePicker className = "col-md-4 col-sm-5" value={singleRideDate} onChange={onSingleRideDateChanged}/>
                </FormRow>

                <FormRow><hr className="col-md-4 col-sm-5 p-0"/></FormRow>

                <FormRow label={<FormattedMessage id="offer.dest_arrival"/>} labelFor="datetimePicker1">
                    <StyledTimePicker className = "col-md-4 col-sm-5" value={moment().startOf('d').add(destArrival, 's')} onChange={onArrivalDateChange}/>
                </FormRow>

                <FormRow label={<FormattedMessage id="offer.dest_departure"/>} labelFor="datetimePicker2" hidden={!isRoundtrip}>
                    <StyledTimePicker className = "col-md-4 col-sm-5" value={moment().startOf('d').add(destDeparture, 's')} onChange={onDepartureDateChange}/>
                </FormRow>

                <FormRow label={<FormattedMessage id="offer.no_roundtrip"/>} labelFor="roundtrip">
                    <span id="roundtrip" className = "col-md-1 col-sm-1 p-0 vertical-align switch-container">
                        <input className="switch mr-2" type="checkbox" id="roundtrip" name="isRoundtrip" checked={!isRoundtrip} onChange={(event) => setIsRoundtrip(!event.target.checked)}/>
                    </span>
                </FormRow>

                <FormRow><hr className="col-md-4 col-sm-5 p-0"/></FormRow>

                <FormRow label={<FormattedMessage id="offer.can_drive"/>} labelFor="canDrive">
                    <span id="canDrive" className = "col-md-1 col-sm-1 p-0 vertical-align switch-container">
                        <input className="switch mr-2" type="checkbox" id="roundtrip" name="canDrive" checked={canDrive} onChange={(event) => setCanDrive(event.target.checked)}/>
                    </span>
                </FormRow>

                <FormRow label={<FormattedMessage id="offer.select_route_car"/>} labelFor="canDrive" hidden={!canDrive}>
                    <span className = "col-md-4 col-sm-5 d-flex p-0">
                        <select className = "select_arrow y-style flex-grow-1" name="locationCode" id="locationCode" value={carId} onChange={(e) => setCarId(e.target.value)}>
                            {props.userInfo.user?.cars?.map(car => <option key={car.id} value={car.id}>{car.toString()}</option>)}
                        </select>
                        <Link to={ROUTES.CARS} className="mx-2 align-self-center"><AddCircleIcon fontSize="large" color="primary" /></Link>
                    </span>
                </FormRow>

                <FormRow label={<FormattedMessage id="offer.maxDetour"/>} labelFor="maxDetour" hidden={!canDrive}>
                    <input className="col-sm-4 y-style flex-grow-1" type="number" min="1" max="60" id="maxDetour" name="maxDetour" placeholder="15" value={maxDetour} onChange={(event) => {
                        const detour = parseInt(event.target.value)
                        if (detour > 60) {
                            return setMaxDetour(60)
                        }
                        return setMaxDetour(detour)
                    }}/>
                </FormRow>

                <FormRow label={<FormattedMessage id="offer.want_reserved_spot"/>} labelFor="wantParkingCheckbox" hidden={!canDrive}>
                    <span className = "col-md-1 col-sm-1 p-0 vertical-align switch-container">
                        <input className="switch" type="checkbox" id="wantParkingCheckbox" name="wantParking" checked={wantParking} onChange={event => setWantParking(event.target.checked)}/>
                    </span>
                </FormRow>

				<FormRow><hr className="col-md-4 col-sm-5 p-0"/></FormRow>

                <FormRow label={<FormattedMessage id="ride.additional_info"/>} labelFor="note">
                    <textarea rows={5} className="col-sm-4 y-style flex-grow-1" id="note" name = "note" placeholder = {props.intl.formatMessage({id: 'offer.pickup_placeholder'})} value={note} onChange={event => setNote(event.target.value) }/>
                </FormRow>

                <div className="row">
                    <span className = "col-md-12">
                        <button type="submit" className="btn hm-btn btn-primary" disabled={isLoading}>{isLoading ? <div className='loader-small'/> : <FormattedMessage id="offer.offer_ride"/>}</button>
                    </span>
                </div>

            </form>
        </div>
    )
}

interface IOfferBaseProps {
    router: any
    intl: any
    firebase: Firebase
}
const OfferBase = (props: IOfferBaseProps) => (
    <div className="caption-container">
        <h3><FormattedMessage id="nav.create_route"/></h3>
        <hr className="d-none d-md-block" />
        <UserStateCheck>
            {userInfo =>
                <Fade><OfferRideForm userInfo={userInfo} user={userInfo.user!} {...props} /></Fade>
            }
        </UserStateCheck>
    </div>
)

const Offer = withRouter(withFirebase(injectIntl(OfferBase)))

export default Offer

function withRouter(Component: any) {
    function ComponentWithRouterProp(props: any) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
        <Component
        {...props}
        router={{ location, navigate, params }}
        />
    );
    }

    return ComponentWithRouterProp;
  }