import { Link } from 'react-router-dom'

import { Match, NewRide, Ride, RideType, RidesPair, Route } from 'components/Firebase';
import { FormattedMessage as FM, FormattedDate, FormattedTime, useIntl } from 'react-intl';

import roadCar from 'assets/icons/road_car_dark.svg';
import { useState } from 'react';
import { ScheduleSelect } from 'shared-components/Components/ScheduleSelect';

import Button from '@mui/material/Button'
import DeleteForever from '@mui/icons-material/DeleteForever'

import Swal from 'sweetalert2/dist/sweetalert2.js'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

interface IRideCellProps {
    ride: Ride
    newRide?: NewRide
    isTemplate?: boolean
}
const RideCell = (props: IRideCellProps) => {
    const ride = props.ride
	const date = props.isTemplate ?
        (ride.repeatValue === 1 ?
            <span><FM id="ride.repeat_every_day" /></span> :
            <span><FM id="ride.repeat_weekly" />, <FormattedDate value={ride.datetime} weekday="long" /></span>)
        :
        <FormattedDate value={ride.datetime} weekday="long" month="long" day="numeric" />
    const time = <FormattedTime value={ride.datetime} hour="numeric" minute="numeric" />

	let link = ride.type === RideType.Recurring && !props.isTemplate ?
        `${ride.type}_${ride.id}/${ride.datetime.getTime()/1000}` :
        `${ride.type}_${ride.id}`

    if (props.newRide) {
        link = `t_${props.newRide.routeId}/${props.newRide.destDatetime.getTime()/1000}`
    }

	const driverName = (ride.driver && ride.driver.name && ride.driver.surname) ? <FM id="ride.hitch_driver" values={{driver: ride.driver.name + " " + ride.driver.surname.substring(0, 1)}} /> : "" //Works as null coalescing operator
	let cost = ride.cost ? <FM id="ride.ride-cost" values={{cost: ride.cost}} /> : ""
    const distanceKm = Math.round((ride.distance ?? 0) * 100) / 100
    let distance = ride.distance !== undefined ? <FM id="ride.distance" values={{distance: distanceKm}} /> : ""

	return (
        <Link to={`/app/rides/${link}`}>
            <div className = {"ride my-2 my-md-3" + (ride.canceled ? " ride--canceled" : "")}>
                <div className="row m-0">
                    <span className = "col-sm-4 ride-cell"><FM id="ride.from" />: <span className="place">{ride.source.name}</span></span>
                    <span className = "col-sm-4 ride-cell d-none d-sm-flex align-items-center"><img src={roadCar} alt = "" className="roadcar-svg m-auto" /></span>
                    <span className = "col-sm-4 ride-cell"><FM id="ride.to" />: <span className="place">{ride.destination.name}</span></span>
                </div>
                <div className="row m-0">
                    <span className = "col-12 ride-cell datetime "><FM id="ride.departure" />: {date}, {time}</span>
                </div>
                <div className="row m-0">
                    <span className = "col-4 ride-cell">{driverName}</span>
                    <span className = "col-4 ride-cell">{distance}</span>
                    <span className = "col-4 ride-cell">{cost}</span>
                </div>
            </div>
        </Link>
	)
}

export {RideCell}

interface IMatchCellProps {
    match?: Match
    rides: RidesPair
}
export const MatchCell = (props: IMatchCellProps) => {
    const ride = props.rides.forward ?? props.rides.backward
    if (!ride) {
        return <></>
    }

	const date = <FormattedDate value={ride.destDatetime} weekday="long" month="long" day="numeric" />
    const arrivalTime = props.rides.forward ? <strong><FormattedTime value={props.rides.forward.destDatetime} hour="numeric" minute="numeric"/></strong> : "-"
    const departureTime = props.rides.backward ? <strong><FormattedTime value={props.rides.backward.destDatetime} hour="numeric" minute="numeric" /></strong> : "-"

    let link = ""
    if (props.match) {
        link = `/app/rides/m_${props.match.id}/${ride.destDatetime.getTime()/1000}`
    } else if (ride.route) {
        link = `/app/rides/t_${ride.routeId}/${ride.destDatetime.getTime()/1000}`
    }

	const driverName = (ride.driver && ride.driver.name && ride.driver.surname) ? <FM id="ride.hitch_driver" values={{driver: ride.driver.name + " " + ride.driver.surname.substring(0, 1)}} /> : ""

    const isEnabled = props.rides.flat().filterUndef().some(r => !r.canceled)

	return (
        <Link to={link}>
            <div className = {"ride my-2 my-md-3" + (isEnabled ? "": " ride--canceled")}>
                {/*Detour: {props.match?.detour}, time delta: {props.match?.timeDelta}, mismatch rating: {props.match?.mismatchRating}, {props.match?.id}*/}
                <div className="row m-0">
                    <span className = "col-sm-4 ride-cell"><FM id="ride.from" />: <span className="place">{ride.route?.origin.name}</span></span>
                    <span className = "col-sm-4 ride-cell d-none d-sm-flex align-items-center"><img src={roadCar} alt = "" className="roadcar-svg m-auto" /></span>
                    <span className = "col-sm-4 ride-cell"><FM id="ride.to" />: <span className="place">{ride.route?.destination.name}</span></span>
                </div>
                <div className="row m-0">
                    <span className = "col-12 ride-cell datetime ">{date}</span>
                </div>
                <div className="row m-0">
                    <span className = "col-4 ride-cell datetime "><FM id="ride.dest_arrival_time" />{arrivalTime}</span>
                    <span className = "col-4 ride-cell datetime "></span>
                    <span className = "col-4 ride-cell datetime "><FM id="ride.dest_departure_time" />{departureTime}</span>
                </div>
                <div className="row m-0">
                    <span className = "col-4 ride-cell">{driverName}</span>
                    <span className = "col-4 ride-cell"></span>
                    <span className = "col-4 ride-cell"></span>
                </div>
            </div>
        </Link>
	)
}

interface IRouteCellProps {
    route: Route
    isTemplate?: boolean
    setRouteCanceled: (routeId: string, canceled: boolean) => Promise<void>
}
export const RouteCell = (props: IRouteCellProps) => {
    const [isLoadingCancelValue, setIsLoadingCancelValue] = useState(false)
    const intl = useIntl()

    const route = props.route

    const arrivalTime = route.destArrival ? <strong><FormattedTime value={route.destArrival * 1000} hour="numeric" minute="numeric" timeZone="UTC" /></strong> : "-"
    const departureTime = route.destDeparture ? <strong><FormattedTime value={route.destDeparture * 1000} hour="numeric" minute="numeric" timeZone="UTC" /></strong> : "-"

    const cancelClicked = () => {
        return MySwal.fire({
            icon: 'question',
            text: intl.formatMessage({id: 'route.delete'}),
            showCancelButton: true,
            confirmButtonText: intl.formatMessage({id: 'general.delete'})
        }).then(result => {
        	if (result.isConfirmed) {
                setIsLoadingCancelValue(true)
                props.setRouteCanceled(route.id, true)
        	}
        })
    }

	return (
        <div className = {"route my-2 my-md-3"}>
            <div className="row m-0 mb-3">
                <span className = "col-sm-10 offset-sm-1 ride-cell"><h2>{route.title}</h2></span>
                <span className = "col-sm-1 ride-cell">
                    {isLoadingCancelValue ?
                        <div className = "loader-small mt-3 mb-1"></div>
                        :
                        <Button color="primary" size="small" onClick={cancelClicked}><DeleteForever /></Button>
                    }
                </span>
            </div>
            <div className="row m-0 my-3">
                <span className = "col-sm-4 ride-cell"><FM id="ride.from" />: <span className="place">{route.origin.name}</span></span>
                <span className = "col-sm-4 ride-cell d-none d-sm-flex align-items-center"><img src={roadCar} alt = "" className="roadcar-svg m-auto" /></span>
                <span className = "col-sm-4 ride-cell"><FM id="ride.to" />: <span className="place">{route.destination.name}</span></span>
            </div>
            <div className="row m-0 my-3 align-items-center">
                <span className = "col-md-4 ride-cell datetime">
                    <FM id="ride.dest_arrival_time" />
                    <span className="place">{arrivalTime}</span>
                </span>
                <span className = "col-md-4 offset-md-4 ride-cell datetime">
                    <FM id="ride.dest_departure_time" />
                    <span className="place">{departureTime}</span>
                </span>
            </div>
            <div className="row m-0 align-items-center justify-content-center">
                <span className = "col ride-cell m-auto"><ScheduleSelect showDate={false} initialSelection={route.schedule.map(x => x-1)}/></span>
            </div>
        </div>
	)
}