declare global {
    interface Array<T> {
        filterUndef(): NonNullable<T>[]
    }
}

if (!Array.prototype.filterUndef) {
    Array.prototype.filterUndef = function <T>(this: Array<T | undefined>) {
        return this.filter(elem => elem).map(elem => elem!)
    }
}

export {}
