import React from 'react';
import GoogleMapReact from 'google-map-react';
import { Link } from 'react-router-dom'
import Swal, { SweetAlertResult } from 'sweetalert2/dist/sweetalert2.js'
import withReactContent from 'sweetalert2-react-content'
import { Fade } from 'react-awesome-reveal';

import Firebase, {withFirebase, RideType, Connection, Ride, NewRide, Match, RidesPair } from 'components/Firebase';
import { UserState, UserStateCheck, UserAuthInfo } from 'shared-components/Session';
import {FormattedMessage as FM, FormattedDate, injectIntl, IntlShape} from 'react-intl';

import WebAPI from 'components/Firebase/webAPI';

import 'styles/rides.scss';
import 'styles/userProfile.scss';
import * as ROUTES from 'constants/routes';
import Config from 'constants/config'

import moment from 'moment';
import { Direction } from 'components/Firebase/models/other';

const MySwal = withReactContent(Swal)

/*global google*/

interface IInfoCellProps {
    hidden?: boolean
    cellTitle: string | JSX.Element
    cellValue: string | JSX.Element
}
const InfoCell = (props: IInfoCellProps) => {
    return props.hidden ? <div></div> :
        <div className="row margin">
            <span className="col-sm-6 text-end">{props.cellTitle}</span>
            <span className="col-sm-6 text-start">{props.cellValue}</span>
        </div>
}

interface IDecisionCellBaseProps {
    firebase: Firebase
    connection: Connection
    refreshState: any //TODO
    cellTitle: string
    cellValue: string
}
class DecisionCellBase extends React.Component<IDecisionCellBaseProps> {

    acceptConnection = () => {
        this.props.firebase.respondToConnection(this.props.connection.id, true)
        .then(this.props.refreshState)
        .catch(error => console.warn("Error accepting ride ", error))
    }

    declineConnection = () => {
        this.props.firebase.respondToConnection(this.props.connection.id, false)
        .then(this.props.refreshState)
        .catch(error => console.warn("Error accepting ride ", error))
    }

    render() {
        return (
            <div className = "row margin">
                <span className = "col-sm-6 text-end">{this.props.cellTitle}</span>
                <span className = "col-sm-2 text-start">{this.props.cellValue}</span>
                <span className = "col-sm-4 text-start">
                    <button type="button" className="btn btn-outline-light btn-sm mx-2" onClick={this.acceptConnection}><FM id="ride.accept"/></button>
                    <button type="button" className="btn btn-outline-light btn-sm mx-2" onClick={this.declineConnection}><FM id="ride.decline"/></button>
                </span>
            </div>
        )
    }
}
const DecisionCell = withFirebase(DecisionCellBase)

interface IWaitingPassengersBlockBaseProps {
    connections: Connection[]
    refreshState: any //TODO
    intl: IntlShape
}
class WaitingPassengersBlockBase extends React.Component<IWaitingPassengersBlockBaseProps> {
    render() {
        if (this.props.connections.length === 0) {
            return <InfoCell cellTitle={this.props.intl.formatMessage({id: 'ride.awaiting_confirmation'})}
                             cellValue={this.props.intl.formatMessage({id: 'ride.nobody_waiting'})} />
        } else {
            let passengersItems = [<DecisionCell key={this.props.connections[0].id}
                                                 cellTitle={<FM id="ride.awaiting_confirmation"/>}
                                                 cellValue={this.props.connections[0].passenger?.name}
                                                 connection={this.props.connections[0]}
                                                 refreshState={this.props.refreshState} />]
            for (let i = 1; i < this.props.connections.length; i++) {
                passengersItems.push(<DecisionCell key={this.props.connections[i].id} cellTitle="" cellValue={this.props.connections[i].passenger?.name} connection={this.props.connections[i]} refreshState={this.props.refreshState} />)
            }
            return <React.Fragment>{passengersItems}</React.Fragment>
        }
    }
}
const WaitingPassengersBlock = injectIntl(WaitingPassengersBlockBase)

interface IPassengersBlockBaseProps {
    firebase: Firebase
    ride: Ride
    intl: IntlShape
}
interface IPassengersBlockBaseState {
    connections: Connection[] | null
}
class PassengersBlockBase extends React.Component<IPassengersBlockBaseProps, IPassengersBlockBaseState> {
    constructor(props: IPassengersBlockBaseProps) {
        super(props)

        this.state = {
            connections: null
        }
    }

    componentDidMount() {
        this.fetchConnections()
    }

    fetchConnections = () => {
        //This needs to fetch connections for backwards ride as well

        this.props.firebase.fetchRideConnections(this.props.ride.id)
        .then(this.props.firebase.fillConnectionsPassengers)
        .then(connections => {
            this.setState({connections: connections.filter(connection => connection.passenger)}) //Take only defined passengers
        })
    };

    render() {
        if (!this.state.connections) {
            return <div><div className = "loader"></div><br /></div>
        }
        let confirmedPassengersStr = this.state.connections.filter(c => c.accepted === true).map(c => c.passenger?.name).join(", ")
        confirmedPassengersStr = (confirmedPassengersStr.length === 0) ? this.props.intl.formatMessage({id: "ride.no_passengers"}) : confirmedPassengersStr

        const canceledConnections = this.state.connections.filter(c => c.canceled)
        const cancelBlock = canceledConnections.length === 0 ? <div></div> : <InfoCell cellTitle={this.props.intl.formatMessage({id: "ride.request_canceled"})} cellValue={canceledConnections.map(c => c.passenger?.name).join(", ")} />

        return(
            <React.Fragment>
                <div className="row margin">
                    <span className="col"><hr /></span>
                    {this.props.ride.newRide ? <span className="col-1">{this.props.ride.newRide?.direction === Direction.Backward ? "←" : "→"}</span> : <></>}
                    <span className="col"><hr /></span>
                </div>
                <InfoCell cellTitle={this.props.intl.formatMessage({id: "ride.passengers"})} cellValue={confirmedPassengersStr} />
                <WaitingPassengersBlock connections={this.state.connections.filter(c => c.accepted === undefined && !c.canceled)} refreshState={this.fetchConnections} />
                {cancelBlock}
            </React.Fragment>
        )
    }
}
const PassengersBlock = injectIntl(PassengersBlockBase)

interface IRideDetailBaseProps {
    firebase: Firebase
    userInfo: UserAuthInfo
    intl: IntlShape
    zoom: number
    center: {lat: number, lng: number}
    router: any
}
interface IRideDetailBaseState {
    ride: Ride | null | undefined
    ridesPair?: RidesPair
    match?: Match
}
class RideDetailBase extends React.Component<IRideDetailBaseProps, IRideDetailBaseState> {
    isInstanceOfRecurring: boolean = false //determines if is generated recurring ride instance
	constructor(props: IRideDetailBaseProps) {
		super(props)

		this.state = {
			ride: undefined
		}
	}

	static defaultProps = {
    	center: {
			lat: 49.195110,
			lng: 16.608193
    	},
    	zoom: 11,
  	};

    componentDidMount() {
        const paramsComposedId = this.props.router.params.rideId
        const paramsDatetime = new Date(this.props.router.params.datetime * 1000)

        if (paramsComposedId[0] === "m") { // Match - if both rides are present, it is the direction forward
            const matchId = paramsComposedId.substring(2)
            this.loadDataByMatch(matchId, paramsDatetime)
        } else if (paramsComposedId[0] === "t") { // Route
            const routeId = paramsComposedId.substring(2)
            this.loadDataByRoute(routeId, paramsDatetime)
        } else { // Legacy (ride)
            this.props.firebase.fetchRideWithComposedId(paramsComposedId)
            .then(ride => {
                if (this.props.router.params.datetime) {
                    ride.datetime = paramsDatetime
                    if (ride.type === RideType.Recurring) {
                        this.isInstanceOfRecurring = true
                        return this.props.firebase.checkForExceptionRide(ride)
                    }
                }
                return Promise.resolve(ride)
            }).then(ride => {
                this.setState({ride})
            }).catch(error => {
                console.log("Error getting ride", error);
                this.setState({
                    ride: null
                })
            })
        }
    }

    loadDataByMatch = async (matchId: string, date: Date) => {
        const user = this.props.userInfo.user
        if (!user || !user.activeLocation) {
            return
        }

        try {
            const match = await this.props.firebase.fetchMatch(matchId)
            //const otherDirectionMatch = match.otherDirectionMatchId ? await this.props.firebase.fetchMatch(match.otherDirectionMatchId) : undefined
            const [passengerRoute, driverRoute] = await Promise.all([
                this.props.firebase.fetchRoute(match.passengerRouteId),
                this.props.firebase.fetchRoute(match.driverRouteId)
            ])

            match.driverRoute = driverRoute
            match.passengerRoute = passengerRoute

            await this.props.firebase.fillRoutesDrivers([passengerRoute, driverRoute])
            const rides = NewRide.fromRoute(driverRoute, date, user.activeLocation.timezone, this.props.firebase.generateRideId)

            // Look for actual ride instances exists
            const rideDateMoment = moment.tz(date, user.activeLocation.timezone)
            const rideInstances = await this.props.firebase.fetchRidesForRoute(driverRoute, rideDateMoment.startOf('d').toDate(), rideDateMoment.endOf('d').toDate())

            // If instances exist, swap them with expended rides
            rides.forward = rideInstances.find(instance => rides.forward?.destDatetime.getTime() === instance.destDatetime.getTime()) ?? rides.forward
            rides.backward = rideInstances.find(instance => rides.backward?.destDatetime.getTime() === instance.destDatetime.getTime()) ?? rides.backward

            // Check direction and match
            if (!match.otherDirectionMatchId) {
                if (match.direction === Direction.Forward) {
                    rides.backward = undefined
                } else {
                    rides.forward = undefined
                }
            }

            this.setState({
                ride: (rides.forward ?? rides.backward)?.asLegacyRide(driverRoute),
                ridesPair: rides,
                match
            })
        } catch (error) {
            console.log("Error fetching match data", error);
            this.setState({
                ride: null
            })
        }
    }

    loadDataByRoute = async (routeId: string, date: Date) => {
        const user = this.props.userInfo.user
        if (!user || !user.activeLocation) {
            return
        }

        try {
            const route = await this.props.firebase.fetchRoute(routeId)
            route.driver = user

            const rides = NewRide.fromRoute(route, date, user.activeLocation.timezone, this.props.firebase.generateRideId)

            // Look for actual ride instances exists
            const rideDateMoment = moment.tz(date, user.activeLocation.timezone)
            const rideInstances = await this.props.firebase.fetchRidesForRoute(route, rideDateMoment.startOf('d').toDate(), rideDateMoment.endOf('d').toDate())

            // If instances exist, swap them with expended rides
            rides.forward = rideInstances.find(instance => rides.forward?.destDatetime.getTime() === instance.destDatetime.getTime()) ?? rides.forward
            rides.backward = rideInstances.find(instance => rides.backward?.destDatetime.getTime() === instance.destDatetime.getTime()) ?? rides.backward

            this.setState({
                ride: (rides.forward ?? rides.backward)?.asLegacyRide(route),
                ridesPair: rides,
            })
        } catch (error) {
            console.log("Error fetching route data", error);
            this.setState({
                ride: null
            })
        }
    }

    handleApiLoaded = (map: any, maps: any) => {
        if (!this.state.ride) {return}
        const directionsService = new google.maps.DirectionsService();
        const directionsDisplay = new google.maps.DirectionsRenderer({
            draggable: false,
        });
        directionsDisplay.setMap(map);
        let request: google.maps.DirectionsRequest = {
            origin: new google.maps.LatLng(this.state.ride.source.lat, this.state.ride.source.lng),
            destination: new google.maps.LatLng(this.state.ride.destination.lat, this.state.ride.destination.lng),
            travelMode: google.maps.TravelMode.DRIVING
        };
        const origin = this.state.match?.passengerRoute?.origin
        if (origin) {
            request.waypoints = [{location: new google.maps.LatLng(origin.lat, origin.lng)}]
        }
        directionsService.route(request, function(response, status) {
            if (status === 'OK') {
                directionsDisplay.setDirections(response);
            } else {
                console.warn("Error getting directions: " + status);
            }
        });
    };

    render() {
    	if (this.state.ride === undefined) {
    		return (
    			<div className = "loader"></div>
    		)
        }

        const user = this.props.userInfo.user
        if (this.state.ride === null || !user || !user.activeLocation) {
            return (<p className="error">Loading ride failed</p>)
        }

        const ride = this.state.ride

        const isMyRide = ride.userId === user.uid
        const isTemplate = ride.type === RideType.Recurring
        const date = isTemplate && !this.isInstanceOfRecurring ?
            (ride.repeatValue === 1 ? <span><FM id="ride.repeat_every_day" /></span> : <span><FM id="ride.repeat_weekly" />, <FormattedDate value={ride.datetime} weekday="long" /></span>) :
            <FormattedDate value={ride.datetime} weekday="long" month="long" day="numeric" />

        let originDepartureTime, destArrivalTime, destDepartureTime, originArrivalTime = undefined
        if (this.state.ridesPair?.forward) {
            const ride = this.state.ridesPair.forward
            const rideDestTime = moment.tz(ride.destDatetime, user.activeLocation.timezone)
            //TODO add duration of accepted connections
            const rideOriginTime = moment(rideDestTime).subtract(ride.route!.duration, 's')

            originDepartureTime = this.props.intl.formatTime(rideOriginTime.toDate(), {hour:'numeric', minute:"numeric"})
            destArrivalTime = this.props.intl.formatTime(rideDestTime.toDate(), {hour:'numeric', minute:"numeric"})
        }
        if (this.state.ridesPair?.backward) {
            const ride = this.state.ridesPair.backward
            const rideDestTime = moment.tz(ride.destDatetime, user.activeLocation.timezone)
            //TODO add duration of accepted connections
            const rideOriginTime = moment(rideDestTime).add(ride.route!.duration, 's')

            destDepartureTime = this.props.intl.formatTime(rideDestTime.toDate(), {hour:'numeric', minute:"numeric"})
            originArrivalTime = this.props.intl.formatTime(rideOriginTime.toDate(), {hour:'numeric', minute:"numeric"})
        }

        const time = (destArrivalTime || destDepartureTime) ? "" : ", " + this.props.intl.formatTime(ride.datetime, {hour:'numeric', minute:"numeric"})

        const driverName = (ride.driver && ride.driver.name && ride.driver.surname) ? (ride.driver.name + " " + ride.driver.surname.substring(0, 1)) : "Unknown"
        const title = isMyRide ? <FM id="ride.my_ride"/> : <Link className="driver-link" to={"/app/user/" + (ride.driver?.uid ?? "")}><FM id="ride.drivers_ride" values={{driverName}}/></Link>
        const repeatInfo = (ride.repeatValue ?? 0) === 0 ? null : ride.repeatValue === 1 ? <FM id="ride_detail.repeat_every_day"/> : <FM id="ride_detail.repeat_weekly"/>

        const passengersBlock = (isMyRide && !isTemplate) ? <PassengersBlock ride={ride} firebase={this.props.firebase} /> : ""

        const legacyBackwardRide = this.state.ridesPair?.backward?.maybeAsLegacyRide()
        const passengersBackBlock = (isMyRide && !isTemplate && legacyBackwardRide && this.state.ridesPair?.forward) ? <PassengersBlock ride={legacyBackwardRide} firebase={this.props.firebase} /> : ""

        return (
            <div>
                <button className="btn btn-outline-primary mb-2" onClick={() => {this.props.router.navigate(-1)}}>❮</button>
                <div className="center-container">
    	            <div className = "ride-detail-tab">
    	                <h2>{title}</h2>
                        <InfoCell cellTitle={<FM id="ride_detail.from"/>} cellValue={ride.source.name} />
                        <InfoCell cellTitle={<FM id="ride_detail.to" />} cellValue={ride.destination.name} />
                        <InfoCell cellTitle={<FM id="ride.ride_date" />} cellValue={<span>{date}{time}</span>} />
                        {originDepartureTime ? <InfoCell cellTitle={<FM id="ride.detail_origin_departure_time" />} cellValue={<span>± {originDepartureTime}</span>} /> : <></>}
                        {destArrivalTime ? <InfoCell cellTitle={<FM id="ride.detail_dest_arrival_time" />} cellValue={<span>{destArrivalTime}</span>} /> : <></>}
                        {destDepartureTime ? <InfoCell cellTitle={<FM id="ride.detail_dest_departure_time" />} cellValue={<span>{destDepartureTime}</span>} /> : <></>}
                        {originArrivalTime ? <InfoCell cellTitle={<FM id="ride.detail_origin_arrival_time" />} cellValue={<span>± {originArrivalTime}</span>} /> : <></>}
                        {/*<InfoCell cellTitle={<FM id="ride.driver" />} cellValue={driverName} hidden={isMyRide || isTemplate} />*/}
                        {/*<InfoCell cellTitle={<FM id="ride.available_seats" />} cellValue={ride.availableSeats ?? ""} hidden={!ride.availableSeats} />*/}
                        <InfoCell cellTitle={<FM id="ride.cost" />} cellValue={ride.cost} hidden={!ride.cost} />
                        <InfoCell cellTitle={<FM id="ride.additional_info" />} cellValue={ride.note ?? ""} hidden={!ride.note} />
                        <InfoCell cellTitle={<FM id="ride.repeat_info" />} cellValue={repeatInfo ?? ""} hidden={!repeatInfo || (isTemplate && !this.isInstanceOfRecurring)} />
                        {passengersBlock}
                        {passengersBackBlock}
                        <div className='row margin'><span className="col"><hr /></span></div>
                        <JoinButton userInfo={this.props.userInfo} ride={ride} isInstanceOfRecurring={this.isInstanceOfRecurring}
                            firebase={this.props.firebase} router={this.props.router} intl={this.props.intl} match={this.state.match} ridesPair={this.state.ridesPair} />
                    </div>
                    <br />
                  	<div id="map">
    			        <GoogleMapReact
    			          	bootstrapURLKeys={{ key: Config.mapsApiKey!}}
    			          	defaultCenter={this.props.center}
    			          	defaultZoom={this.props.zoom}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
    			        >
    			        </GoogleMapReact>
    			    </div>
                </div>
            </div>
        )
    }
};

interface IJoinButtonProps {
    firebase: Firebase
    userInfo: UserAuthInfo
    ride: Ride
    router: any
    intl: IntlShape
    isInstanceOfRecurring: boolean
    match?: Match
    ridesPair?: RidesPair
}
interface IJoinButtonState {
    connection: Connection | undefined | null
    joiningInProgress: boolean

}
class JoinButton extends React.Component<IJoinButtonProps, IJoinButtonState> {
    constructor(props: IJoinButtonProps) {
        super(props)

        this.state = {
            connection: undefined,
            joiningInProgress: false,
        }
    }

    componentDidMount() {
        this.refreshConnections()
    }

    refreshConnections = () => {
        if (this.props.userInfo.user!.uid) {
            this.props.firebase.fetchPassengerRideConnections(this.props.userInfo.user!.uid, this.props.ride.id)
            .then(connections => {
                if (connections.length > 0) {
                    this.setState({connection: connections[0]})
                } else {
                    this.setState({connection: null})
                }
            }).catch(error => {
                console.warn("Error fetching connection: ", error)
            })
        }
    };

    onSubmit = async (event: React.FormEvent<HTMLElement>) => {
        event.preventDefault()

        if (!this.props.userInfo.authUser!.emailVerified) {
            return this.props.router.navigate(ROUTES.REVERIFY_EMAIL)
        }

        this.setState({joiningInProgress: true})
        try {
            if (this.props.match) {
                let requests = []
                if (this.props.ridesPair?.flat().length === 2) {
                    requests = [
                        {matchId: this.props.match.id, destDatetime: this.props.ridesPair!.rideForDirection(this.props.match.direction)!.destDatetime},
                        {matchId: this.props.match.otherDirectionMatchId!, destDatetime:this.props.ridesPair!.rideForOppositeDirection(this.props.match.direction)!.destDatetime}
                    ]
                } else {
                    const rides = this.props.ridesPair?.flat() ?? []
                    requests = rides.map(ride => { return {matchId: this.props.match!.id, destDatetime: ride.destDatetime}})
                }
                await WebAPI.joinRides(requests)
            } else {
                await this.props.firebase.joinRide(this.props.ride, this.props.userInfo.user)
            }
            this.setState({joiningInProgress: false})
            await MySwal.fire({
                icon: 'success',
                title: this.props.intl.formatMessage({id: 'ride.request_sent'}),
                text: this.props.intl.formatMessage({id: 'ride.request_response'}),
            })
            this.props.router.navigate(-1)
        } catch(error) {
            MySwal.fire({
                icon: 'error',
                text: this.props.intl.formatMessage({id: 'general.processing_error'}),
            })
            console.warn("Error writing document: ", error)
        }
    };

    onSubmitForWeek = async (event: React.FormEvent<HTMLElement>) => {
        event.preventDefault()

        if (!this.props.userInfo.authUser!.emailVerified) {
            return this.props.router.navigate(ROUTES.REVERIFY_EMAIL)
        }

        this.setState({joiningInProgress: true})
        try {
            if (this.props.match) {
                const ride = this.props.ridesPair?.flat()[0]
                const driverRoute = ride?.route
                const location = this.props.userInfo.user?.activeLocation
                const match = this.props.match
                if (!driverRoute || !location || !match) {
                    throw Error("No driver route, cannot proceed")
                }
                const startDate = moment.tz(ride.destDatetime, location.timezone).toDate()
                const endDate = moment(startDate).endOf('week').subtract(2, 'days').toDate()
                const driverRideInstances =  await this.props.firebase.fetchRideInstancesForRoutes([driverRoute], startDate, endDate)
                const driverRides = this.props.firebase.expandAndFilterRoutes(location, [driverRoute], driverRideInstances, startDate, endDate)

                const requests = []
                for (const ride of driverRides) {
                    // Skip missing schedule
                    const rideWeekday = moment(ride.destDatetime).isoWeekday()
                    if (!match.passengerRoute?.schedule.includes(rideWeekday)) {
                        continue
                    }

                    if (ride.direction === Direction.Forward) {
                        requests.push({matchId: match.id, destDatetime: ride.destDatetime})
                    } else {
                        requests.push({matchId: match.otherDirectionMatchId ?? match.id, destDatetime: ride.destDatetime})
                    }
                }
                await WebAPI.joinRides(requests)
            } else {
                await this.props.firebase.joinRideForWeek(this.props.ride, this.props.userInfo.user)
            }
            this.setState({joiningInProgress: false})
            await MySwal.fire({
                icon: 'success',
                title: this.props.intl.formatMessage({id: 'ride.request_sent'}),
                text: this.props.intl.formatMessage({id: 'ride.request_response'}),
            })
            return this.props.router.navigate(-1)
        } catch (error) {
            MySwal.fire({
                icon: 'error',
                text: this.props.intl.formatMessage({id: 'general.processing_error'}),
            })
            console.warn("Error writing document: ", error)
        }
    };

    onRideCancel = (event: React.FormEvent<HTMLElement>) => {
        event.preventDefault();
        const ride = this.props.ride
        let stepOne: Promise<void | SweetAlertResult<any>>
        if (ride.type === RideType.Single) {
            stepOne = Promise.resolve()
        } else {
            const isTemplate = (this.props.ride.type === RideType.Recurring && !this.props.isInstanceOfRecurring)
            stepOne = MySwal.fire({
                icon: 'question',
                title: this.props.intl.formatMessage({id: 'ride_detail.cancel_repeat'}),
                text: this.props.intl.formatMessage({id: !isTemplate ? 'ride_detail.cancel_repeat_detail' : 'ride_detail.cancel_template_detail'}),
                showConfirmButton: !isTemplate,
                showCancelButton: true,
                showDenyButton: true,
                confirmButtonText: this.props.intl.formatMessage({id: 'ride_detail.cancel_this'}),
                denyButtonText: this.props.intl.formatMessage({id: 'ride_detail.cancel_all'}),
                cancelButtonText: this.props.intl.formatMessage({id: 'ride_detail.cancel_cancel'}),
            })
        }
        stepOne.then((result): Promise<any> => {
            if (!result) { //Single ride
                if (this.props.ridesPair) {
                    return WebAPI.cancelRides(this.props.ridesPair.flat().filterUndef())
                } else if (ride.newRide) {
                    return WebAPI.cancelRides([ride.newRide])
                } else {
                    return this.props.firebase.cancelRide(ride)
                }
            } else if (result.isDismissed) {
                return Promise.reject(new Error('closed')) //Dialog closed
            } else if (result.isConfirmed) { //Cancel this exception
                if (ride.type === RideType.Exception) {
                    return this.props.firebase.cancelRide(ride)
                } else { //Recurring
                    return this.props.firebase.cancelRecurringRideException(ride)
                }
            } else { //result.isDenied, canceling all future rides
                if (ride.type === RideType.Exception) {
                    return Promise.all([
                        this.props.firebase.cancelRecurringRide(ride.recurringRideId ?? ""),
                        this.props.firebase.cancelRide(ride)
                    ])
                } else { //Recurring
                    return this.props.firebase.cancelRide(ride)
                }
            }
        }).then(_ =>
            MySwal.fire({
                icon: 'success',
                title: this.props.intl.formatMessage({id: 'ride.canceled'}),
                text: this.props.intl.formatMessage({id: 'ride.canceled_detail'}),
            })
        ).then(_ => {
            this.props.router.navigate(-1)
        }).catch(error => {
            if (error.message === 'closed') {return}
            MySwal.fire({
                icon: 'error',
                text: this.props.intl.formatMessage({id: 'general.processing_error'}),
            })
            console.warn("Error writing document: ", error)
        })
    };

    onConnectionCancel = (event: React.FormEvent<HTMLElement>) => {
        if (!this.state.connection) {return}
        this.props.firebase.cancelConnection(this.state.connection.id)
        .then(docRef => {
            return MySwal.fire({
                icon: 'success',
                title: this.props.intl.formatMessage({id: 'ride.connection_canceled'}),
                text: this.props.intl.formatMessage({id: 'ride.connection_canceled_detail'}),
            })
        }).then(_ => {
            this.refreshConnections()
        }).catch(error => {
            MySwal.fire({
                icon: 'error',
                text: this.props.intl.formatMessage({id: 'general.processing_error'}),
            })
            console.warn("Error writing document: ", error)
        })
        event.preventDefault();
    };

    render() {
        //No user signed in or user not loaded yet
        if (this.props.userInfo.state === UserState.NoUser || this.props.userInfo.state === UserState.Unknown) {
            return ""
        }

        if (this.props.ride.canceled) {
            return <button type="submit" className="hm-btn btn btn-light" disabled><FM id="ride.ride_canceled"/></button>
        }

        if (this.props.ride.userId === this.props.userInfo.user!.uid) {
            return (
                <form onSubmit={this.onRideCancel}>
                    <button type="submit" className="hm-btn btn btn-outline-light"><FM id="ride.cancel_ride"/></button>
                </form>
            )
        }

        if (this.state.connection === undefined) {
            return <div className = "loader"></div>
        }

        if (this.state.connection) { //There is already connection for this ride and this passenger
            if (this.state.connection.canceled === true) {
                return <InfoCell cellTitle="Status" cellValue={<FM id="ride.you_canceled"/>} />
            }
            if (this.state.connection.accepted === undefined) {
                return (
                    <form onSubmit={this.onConnectionCancel}>
                        <InfoCell cellTitle="Status" cellValue={<FM id="ride.awaiting_confirmation"/>} />
                        <button type="submit" className="hm-btn btn btn-outline-light"><FM id="ride.cancel_request"/></button>
                    </form>
                )
            } else if (this.state.connection.accepted === true) {
                return (
                    <form onSubmit={this.onConnectionCancel}>
                        <InfoCell cellTitle="Status" cellValue={<FM id="ride.connection_accepted"/>} />
                        <button type="submit" className="hm-btn btn btn-outline-light"><FM id="ride.cancel_connection"/></button>
                    </form>
                )
            } else if (this.state.connection.accepted === false) {
                return <InfoCell cellTitle="Status" cellValue={<FM id="ride.connection_declined"/>} />
            }
        } else { //No connection yet for this user
            if (this.props.ride.availableSeats === (0).toString()) { //No more available seats
                return <InfoCell cellTitle="Status" cellValue={<FM id="ride.ride_full"/>} />
            } else {
                if (this.state.joiningInProgress) {
                    return (<div className = "loader"></div>)
                } else {
                    return (
                    <div>
                        <button onClick={this.onSubmit} className="hm-btn btn btn-outline-light m-3" disabled={this.state.joiningInProgress}>
                            <span><FM id="ride.join_ride"/></span>
                        </button>
                        {this.props.ride.repeatValue === 1 || this.props.match ?
                            <button onClick={this.onSubmitForWeek} className="hm-btn btn btn-outline-light m-3" disabled={this.state.joiningInProgress}>
                                <FM id="ride.join_ride_for_week"/>
                            </button> : ""}
                    </div>)
                }
            }
        }
    }
}

const RideDetailContainer = (props: any) => (
    <div className="caption-container">
        <h3><FM id="ride.detail"/></h3>
        <hr className="d-none d-md-block" />
        <UserStateCheck>
            {userInfo =>
                <Fade><RideDetailBase {...props} userInfo={userInfo}/></Fade>
            }
        </UserStateCheck>
    </div>
)

const RideDetail = withRouter(injectIntl(withFirebase(RideDetailContainer)));

export default RideDetail

import {
    useLocation,
    useNavigate,
    useParams,
  } from "react-router-dom";

function withRouter(Component: any) {
    function ComponentWithRouterProp(props: any) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
        <Component
        {...props}
        router={{ location, navigate, params }}
        />
    );
    }

    return ComponentWithRouterProp;
  }