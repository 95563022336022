import { createTheme } from '@mui/material'
import styles from 'styles/color-palette.scss';

declare module '@mui/material' {
    interface Palette {
       
    }
    interface PaletteOptions {
   
    }
}


const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: "'Work Sans', sans-serif",
        fontSize: '1rem'
      },
    },
    palette: {
        text: {
            primary: styles.textPrimary
        },
        primary: {
          main: styles.colorPrimary
        },
        secondary: {
            main: styles.colorSecondary
        }
    }
})

export default theme