export const RIDES = '/app/rides';
//export const FIND_RIDE = '/app/find-ride';
export const CONNECTIONS = '/app/connections';
export const HOME = CONNECTIONS;
export const RIDES_MAP = '/app/rides-map';
export const RIDE_DETAIL = '/app/rides/:rideId';
export const RIDE_DETAIL_DATETIME = '/app/rides/:rideId/:datetime';
export const MY_RIDES = '/app/my-rides';
export const OFFER = '/app/offer';
export const MY_ROUTES = '/app/my-routes';
export const ACCEPT = '/app/accept/:connectionId';
export const DECLINE = '/app/decline/:connectionId';
export const USER_PROFILE = '/app/user/:userId';

export const PROFILE = '/app/profile';
export const EDIT_PROFILE = '/app/edit-profile';
export const SIGN_UP = '/app/signup';
export const SIGN_IN = '/app/signin';
export const CARS = '/app/cars';
export const SIGN_IN_FINISH = CONNECTIONS;

export const ADMIN = '/admin';
export const HM_ADMIN = '/hm-admin';
export const ANALYTICS = '/analytics';
export const USER_MANAGEMENT = '/user-management';
export const USER_MGMT = '/user-mgmt';
export const REVERIFY_EMAIL = '/reverify-email';

export const BENEFITS = '/benefits';
export const ABOUT = '/about';
export const CONTACTS = '/contacts';
export const PARKING_TERMS = '/parking-terms';
export const HOW_IT_WORKS = '/how-it-works';
export const HOW_IT_WORKS_JEDU = '/how-it-works-jedu';
export const COMPETITION = '/competition';
export const COMPETITION_RULES = '/competition-rules';
export const FAQ = '/faq';