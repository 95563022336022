import React from 'react';
import { Link } from "react-router-dom";
import { Fade } from 'react-awesome-reveal';
import { UserStateCheck } from 'shared-components/Session';

import {withFirebase} from 'components/Firebase';
import {FormattedMessage} from 'react-intl';

import {ReactComponent as RoadCar} from 'assets/icons/road_car_blue.svg';

const RequestState = {
    Pending: 0,
    Finished: 1,
    Failed: 2
};

class DeclineBase extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            requestState: RequestState.Pending
        };
    }

    componentDidMount() {
        this.respond(false)
    }

    respond = accepted => {
        this.props.firebase.respondToConnection(this.props.router.params.connectionId, accepted)
        .then(_ => {
            this.setState({requestState: RequestState.Finished})
        }).catch(error => {
            console.warn("Error declining ride ", error)
            this.setState({requestState: RequestState.Failed})
        })
    };

    acceptRide = () => this.respond(true);

    render() {
        switch (this.state.requestState) {
            case RequestState.Pending:
                return (
                    <React.Fragment>
                        <h2><FormattedMessage id="decline.declining"/></h2>
                        <div className = "loader"></div>
                    </React.Fragment>
                )
            case RequestState.Finished:
                return (
                    <React.Fragment>
                        <h2><FormattedMessage id="decline.ride_declined"/></h2>
                        <div className = "box-center bigger-text">
                            <div className = "content-left">
                                <FormattedMessage id="decline.ride_declined_1"/><br />
                                <FormattedMessage id="decline.ride_declined_2"/><br />
                                <FormattedMessage id="decline.ride_declined_3"/><br />
                                <div className = "text-center">
                                    <RoadCar className="w-70 m-3" /><br />
                                </div>
                                <FormattedMessage id="decline.ride_declined_4"/><br />
                                <FormattedMessage id="decline.ride_declined_5"/><br /><br />
                                <div className = "text-center">
                                    <Link to={"/app/accept/"+this.props.router.params.connectionId}>
                                        <button type="button" className="hm-btn btn btn-primary"><FormattedMessage id="decline.ride_declined_confirm"/></button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )
            default: //RequestState.Failed
                return (
                    <React.Fragment>
                        <h2><FormattedMessage id="accept.request_failed"/></h2>
                        <div className = "box-center bigger-text">
                            <div className = "content-left">
                            <FormattedMessage id="accept.try_again_later"/>
                            </div>
                        </div>
                    </React.Fragment>
                )
        }
    }
}

const Decline = props => (
    <div className="caption-container center-container">
    <br />
    <UserStateCheck allowNoUser={true}>
        {userInfo =>
                <Fade><DeclineBase {...props} /></Fade>
        }
    </UserStateCheck>
    </div>
)

export default withRouter(withFirebase(Decline));

import {
    useLocation,
    useNavigate,
    useParams,
  } from "react-router-dom";

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
        <Component
        {...props}
        router={{ location, navigate, params }}
        />
    );
    }

    return ComponentWithRouterProp;
  }